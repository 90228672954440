.order-search-guest  {
	padding-top: 60px;
	padding-bottom: 40px;

	.order-search-render {
		max-width: 340px;
		margin-left: auto;
		margin-right: auto;

		.title {
			font-size: 36px;
		}
	}

	.alert {
		background-color: #f7f7f7;
		margin-bottom: 30px;
		padding: 20px;

		&.error {
			border-bottom: 4px solid #C82828;
		}

		.title {
			font-size: 14px;
			text-transform: uppercase;
			color: #000;
		}

		.info {
			font-size: 14px;
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
}

.order-search-form {
	.row-actions {
		margin-top: 20px;
	}
}
