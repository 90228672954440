.product-promo-horizontal {
  margin-bottom: 100px;

  @include breakpoint (small only) {
    padding: 0 10vw;
    margin-bottom: 50px;


    & &__image {
      img {
        height: 80vw;
      }
    }

    .box-reduced & {
      padding: 0;

      & &__image {
        img {
          height: 100vw;
        }
      }

      &__editorial-box {
        padding-left: 10vw;
        padding-right: 10vw;
      }
    }

    &.widget .button.editorial {
      border-bottom: 2px solid #DBB19F;
  
      &::after {
        display: none;
      }
    }
  }

  .button.editorial {
    margin-top: 30px;

    @include breakpoint(small only) {
      &::before,
      &::after {
        display: none;
      }

      text-indent: 0;
      width: unset;
      height: unset;
      border-radius: 0;
      background-color: unset;
      margin-top: 15px;
    }

  }

  @include breakpoint (medium down) {
    [data-video] {
      margin: 0;
    }

    & &__video + &__editorial-box {
      padding: 50px 0 40px 0;

    }
  }

  &__container {
    background: linear-gradient(0deg, $primary-color calc(100% - 120px), white calc(100% - 120px));

    @include breakpoint (small only) {
      background: linear-gradient(0deg, $primary-color calc(100% - 60px), white calc(100% - 60px));
    }

    &.box-reduced {
      background: none;
    }

    &.box-transparent {
      background: none;
    }
  }

  &__video {
    img {
      width: 100%;
    }
  }

  &__editorial-box {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0 40px 0;
    text-align: center;

    @include breakpoint (small only) {
      width: 100%;
      padding: 50px 0 30px 0;
    }

    &.box-reduced {
      background-color: $primary-color;
    }

    &.box-transparent {
      background-color: transparent !important;
    }

  }

}