$include-fonts: false;

@import '../setup/settings';
@import '../../../node_modules/foundation-sites/scss/foundation';
@import 'pdp/vars';

.business-landing {
 background-color: $primary-color;
 min-height: 100vh;

 .logo-container {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px 0;
   min-height: 15vh;
 }

 .landing-content {
    min-height: 85vh;
    display: flex;
    align-items: center;
    .form-container {
      width: 100%;
      transform: translateY(-25%);
      .button {
        margin-top: 32px;
      }
      input, select {
        border: 1px solid black;
        background-color: transparent;
       }
    }
 }

 .landing-thankyou {
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .thankyou-title {
    text-align: center;
  }
  
  svg.heart-icon {
    fill: $secondary-color;
    margin-bottom: 24px;
  }

  .button.primary {
    margin-top: 36px;
  }
  
 }

 .form-register-content {
   h2.form-title {
     display: none;
   }

   input, select {
     border: 1px solid black;
     background-color: transparent;
    }

    span.radio-input, span.checkbox-input {
      &::before {
        border: 1px solid black;
      }
    }

    .custom-select {
      button:not(.lang){
        border: 1px solid black;
      }
    }
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px $primary-color inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.business-landing {
  @include breakpoint(medium down) {
    
  }
}