.add-to-cart-messages, .add-to-wl-messages {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 60px;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	background-color: #F2DFD6;
	opacity: 0;
	z-index: 100;
	transition: opacity 0.3s ease, visibility 0.3s ease;

	&.show {
		opacity: 1;
		visibility: visible;
	}

	div, a{
		display: block;
		width: 100%;
	}

	a {
		padding: 20px;
	}
}