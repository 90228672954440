.header-toolbar {
    @include flex;
    justify-content: flex-end;
    margin-bottom: 0;

    > li {
        width: 26px;
        height: 26px;
        margin: 0 3px;

        > a {
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            @include flex;
            @include flex-align(center, middle);
            z-index: 1001;
        }
    }
    .header-popup-wrapper-width {
        width:auto;

        .loyalty-icon-text-account {
            padding-left: 7px;
            padding-top: 5px;
        }
    }

    .loyalty-icon-text-account-li{
        width:auto;
    }

    svg {
        width: 16px;
        height: 16px;
    }

    .search-item {
        span {
            position: absolute;
            width: 110px;
            border-bottom: 1px solid $black;
            height: 16px;
            line-height: 14px;
            right: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @include breakpoint(large down) {
                display: none;
            }
        }

        a {
            transition: color 0.3s ease;
        }
    }

    .wishlist-icon {
        svg {
            transition: visibility 0.3s ease, opacity 0.3s ease;
        }

        .wishlist-list {
            position: absolute;
            visibility: hidden;
            opacity: 0;
        }

        &.has-wishlist-list {
            .wishlist-list {
                visibility: visible;
                opacity: 1;
            }

            .wishlist-empty {
                position: absolute;
                visibility: hidden;
                opacity: 0;
            }
        }
    }
}

.mini-cart-link,
.mini-cart,
.mini-cart-mobile {
    > .minicart-quantity {
        font-size: 12px;
        width: 16px;
        height: 16px;
        line-height: 15px;
        font-weight: 500;
        color: $white;
        background-color: $black;
        display: block;
        border-radius: 8px;
        position: absolute;
        top: 0px;
        right: -2px;
        text-align: center;
    }
}

.mini-cart{
    .header-popup{
        display: flex;
        flex-direction: column;
        max-height: 88vh;
        overflow: unset;
        background-color: white;

        .header-popup-footer {
            background-color: $whitesmoke;
            padding: 20px 25px;
            flex-shrink: 0;

            .paypal-option-text{
                display: none;
            }

            /*.overlap-container > div:first-child {
                position: static !important;
            }*/

            #paypal-button-container, .paypal-buttons{
                height: 44px !important;
            }

        }

        .mini-cart-content{
            display: grid;
            grid-template-rows: minmax(0, auto) auto minmax(0, auto);
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            &.same-space-partition{
                grid-template-rows: 1fr auto 1fr;
            }

            .header-popup-body{
                display: flex;
                flex-direction: column;
                overflow: hidden;
                padding-bottom: 20px;
                padding-top: 20px;
            }

        }

        .einstein-container{
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .mini-cart-footer {
                padding: 20px 30px;
            }

            & > div, .mini-cart-footer {
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow: hidden;
            }

            .header-popup-footer {
                background-color: white;

                .items{
                    flex: 1;
                    overflow-y: auto;
                    min-height: 0;

                    .product-tile{
                        padding: 0;
                        margin-bottom: 20px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

            }
        }

    }
}

.mini-cart-mobile {
    > .minicart-quantity {
        top: 15px;
        right: 11px;
    }
}

.mini-cart {
    &.mobile-toggle-button {
        padding: 0 0 0 20px;
    }

    > .minicart-quantity {
        top: -4px;
        right: -8px
    }
}



.header-popup-wrapper {
    position: relative;

    &.mobile-login{
        position: unset;

        &.loading{
            .header-popup{
                display: flex;
                flex-direction: column;

                .login-mobile-spinner {
                    flex: 1;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .header-popup {
            left: 0;
            right: 0;
            top: 0 !important;
            width: 100%;
            height: 100vh;
            max-height: 100vh;
            background-color: white;
        }

        .close-login-popup{
            position: absolute;
            right: 30px;
            top: 30px;
        }

        &:not(.logged-user) .header-popup-body{
            padding-top: 50px;
        }

        &.logged-user .header-popup-body{
            padding: 0;

            .dashboard-login-header{
                padding: 80px 35px 30px;

                .back-to-shopping-mobile-wrapper, .loyalty-button-logout-mobile{
                    height: 44px;
                    border: 1px solid #000;
                    text-decoration: none;
                }

                .back-to-shopping-mobile-wrapper{
                    background-color: black;
                    margin-bottom: 15px;
                    margin-top: 30px;
                    padding: 10px 30px;

                    a {
                        color: white;
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }

            .loyalty-logo-container{
                padding-right: 10px;
            }
        }

    }

    &.open,
    &:hover:not(.user) {
        .header-popup {
            // display: block;
            top: 35px;
            visibility: visible;
            opacity: 1;
            pointer-events: auto;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #F7F7F7;
            }

            &::-webkit-scrollbar-thumb {
                background: black;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: black;
            }
        }
    }

    &.popup-open {
        .header-popup {
            // display: block;
            top: 35px;
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.header-popup {
    position: absolute;
    // top: 35px;
    top: 15px;
    right: -19px;
    width: 430px;
    border: 1px solid #DBB19F;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease-in-out;
    pointer-events: none;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
    max-height: 88vh;
    overflow: auto;

    &::after {
        content: '';
        top: -20px;
        right: 21px;
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent transparent $white transparent;
        border-width: 10px;
        border-style: solid;
    }

    &::before {
        content: '';
        top: -22px;
        right: 20px;
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent transparent $primary-color transparent;
        border-width: 11px;
        border-style: solid;
        pointer-events: none;
    }

    p {
        font-size: rem-calc(14);
    }

    ul {
        margin-bottom: $paragraph-margin-bottom;
    }
}

.header-popup-body {
    padding: 30px;
    background-color: $white;
}

.header-popup-footer {
    background-color: $primary-color;
    padding: 30px;
}

.header-popup-menu {
    margin: 0;
    margin-bottom: 0 !important;
    padding: 0;

    li {
        display: block;
        border-bottom: 1px solid $light-gray;
        background-color: $whitesmoke;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $primary-color;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    a {
        display: block;
        padding: 15px 20px;
        font-size: rem-calc(16);
        font-weight: 500;
        transition: color 0.3s ease;
    }
}
.header-popup-footer {
    .button.primary.hollow.expanded.no-margin.loyalty-signup-button {
        background-color: #E60C68;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-family: Sofia Pro;
        font-weight: 500;
        line-height: 17.6px;
        border: 0;
    }

    .button.primary.hollow.expanded.no-margin.loyalty-signup-button:hover {
        background-color: #000;
        color: #FFFFFF;
    }

    .label-header-loyalty-signup-details {
        display: flex;
        width: 299px;
        flex-direction: column;
        justify-content: center;
        color: #000;
        font-size: 13px;
        font-family: Sofia Pro;
        font-weight: 300;
        line-height: 17.6px;
    }
    svg {
        width:auto;
        height: auto;
    }
    .label-loyalty-foryou
    {
        display: flex;
        width: 67.274px;
        height: 33.794px;
        flex-direction: column;
        justify-content: center;
        color: #000;
        font-size: 14.347px;
        font-family: Fortescue Pro;
        font-style: normal;
        font-weight: 700;
        line-height: 0px;
        margin-left: 3px;
    }
    .login-loyalty-logo-container {
        display:flex;
    }

}
.loyalty-icon-color-account-0 {
    fill: #CF8973;
}
.loyalty-icon-color-account-1 {
    fill: #CF8973;
}
.loyalty-icon-color-account-2 {
    fill: #B3B3B3;
}
.loyalty-icon-color-account-3 {
    fill: #EBD485;
}
.loyalty-icon-color-account-4 {
    fill: #000000;
}
.loyalty-icon-color-account-5 {
    fill: #6EB3DB;
}

.header-toolbar {
    .loyalty-icon-text-account {
        color: #000;
        font-size: 13px;
        font-family: Sofia Pro;
        font-weight: 500;
        line-height: 22.4px;
        text-transform: uppercase;
    }
}

.loyalty-circle-red-icon-login {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: #E60C68;
    position: absolute;
    top: -4px;
    right: 0px;
    animation: moveBall 0.8s linear infinite;
}

@keyframes moveBall {
    0% {
        top: -7px;
    }
    50% {
        top: -4px;
    }
    100% {
        top: -7px;
    }
}

.cell-left{
    .loyalty-icon-account {
        display: flex;
        align-items: center;
        padding-left: 27px;
        position: relative;
    }
}