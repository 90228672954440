.loyalty-content {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.progress-loyalty-bar {
	margin-top: 60px;

	@include breakpoint(small only) {
		display: flex;
		flex-direction: column-reverse;
		margin-top: 70px;
	}

	.row-steps {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.15;
		color: #000;
		cursor: default;
		user-select: none;

		@include breakpoint(small only) {
			margin-top: 10px;
		}

		.price {
			&:not(:first-child):not(:last-child) {
				@include breakpoint(small only) {
					display: none;
				}
			}
		}
	}

	.progress-bar {
		position: relative;
		width: 100%;
		height: 12px;
		background-color: #f7f7f7;
		border: 1px solid #ccc;
		border-radius: 10px;
		margin-top: 10px;

		@include breakpoint(small only) {
			border: 0;
			margin-top: 0;
		}
	}

	.progress {
		position: absolute;
		top: -1px;
		left: -1px;
		height: 12px;
		background-color: #F2DFD6;
		border-radius: 10px;
	}

	.range {
		position: absolute;
		top: -1px;
		width: 12px;
		height: 12px;
		background-color: #DBB19F;
		border-radius: 50%;
		cursor: pointer;

		&:hover {
			.price-tooltip {
				visibility: visible;
				opacity: 1;
				transform: translate3d(-50%, 0, 0);
			}
		}
	}

	.price-tooltip {
		visibility: hidden;
		position: absolute;
		left: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		color: #000;
		background-color: #F2DFD6;
		border-radius: 6px;
		bottom: 100%;
		opacity: 0;
		margin-bottom: 10px;
		margin-left: 6px;
		padding: 6px 5px;
		transform: translate3d(-50%, -10px, 0);
		transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;

		@include breakpoint(small only) {
			visibility: visible;
			opacity: 1;
			transform: translate3d(-50%, 0, 0);
		}

		.value {
			margin-right: 4px;
		}

		&::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			width: 0;
			height: 0;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 6px solid #F2DFD6;
			margin-left: -6px;
		}
	}
}

.loyalty-list-info-steps {
	font-size: 14px;
	margin-top: 20px;

	@include breakpoint(small only) {
		margin-top: 30px;
	}

	.list {
		margin-bottom: 0;
	}

	.description {
		font-size: 14px;
		margin-top: 20px;
		margin-bottom: 0;

		.link {
			color: #000;
		}
	}
}


.loyalty-page-container{
	margin-top: 35px;
	.main-form-group{
		@include breakpoint(medium down) {
			padding:0px;
			display: flex;
			justify-content: space-around;
			//margin-bottom: 15px;
		}
	}

	.loyalty-logo-container{
		text-align: center;
		height: 160px;
		background-color: #F2DFD6;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.loyalty-label-text{
		font-family: 'Sofia Pro';
		font-weight: 500;
		font-size: 18px;
		color: $black;
	}

	.loyalty-logo-image{
		padding: 15px;
	}
	.loyalty-name-info{
		background-color: #FB748D;
		height: 40%;
		padding: 10px;
		@include breakpoint(medium down) {
			height: 100%;
			display: flex;
			flex-direction: column;
			padding-bottom: 0px;
			display: flex;
			justify-content: center;
		}
	}
	.loyalty-container-progressbar{
		background-color: #FCBFB3;
		height: 60%;
		@include breakpoint(medium down) {
			padding: 10px;
		}
	}
	.loyalty-text-center{
		text-align: center;
		//width: 160px;
	}
	.loyalty-cta-right{
		margin-left: 15px;
		@include breakpoint(medium down) {
			margin-left: 0px;
		}
	}
	.loyalty-cta-container{

		.loyalty-cta{
			white-space: normal;
			line-height: 1;
			border: 0;
			background-color: #F2DFD6;
			font-family: 'Sofia Pro';
			font-weight: 300;
			font-size: 15px;
			color: $black;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}


		.loyalty-text-paddingleft{
			padding-left: 35px;
		}
	}
	.progress-loyalty-bar{
		margin-top: 0px;
		@include breakpoint(medium down) {
			margin-top: 10px;
		}
		.progress-bar{
			display: flex;
			border-radius: 0;
			.loyalty-progress-start{
				background-color: #E60C68;
			}
			.loyalty-progress-pending{
				background-color: #FB748D;
			}
		}
	}
	.container-amount{
		display: flex;
		justify-content: space-between;
	}
	.container-color-amount{
		display: flex;
		align-items: center;
		.loyalty-color-text{
			margin-right: 8px;

		}
		.loyalty-color-amount{
			background-color: #E60C68;
			width: 10px;
			height: 10px;
			border-radius: 100%;
		}
		.loyalty-color-pending{
			background-color: #FB748D;
			width: 10px;
			height: 10px;
			border-radius: 100%;
		}
	}
	.padding-left-loyalty{
		padding-left: 15px;
	}
	.loyalty-expiration-container{
		display: flex;
		align-items: center;
		justify-content: space-around;
		@include breakpoint(medium down) {
			justify-content: space-between;
			margin-top: 10px;
		}

	}
	.container-loyalty-customerservice{
		color: white;
		background-color: #E60C68;
		height: 20%;
		padding-left: 15px;
		padding-right: 40px;
		align-items: center;
		font-size: 13px;
	}
	.height-loyalty-four{
		height: 30%;
		@include breakpoint(medium down) {
			height: 100%;
		}
	}
	.height-loyalty-five{
		height: 50%;
	}
	.loyalty-big-container{
		height: 205px;
		@include breakpoint(medium down) {
			height: 160px;
		}
	}
	/*.container-image-loyalty{
		height: 384px;
		@include breakpoint(medium down) {
			height: 225px;
		}
	}*/
	/*.container-image-loyalty > div:first-child {
		height: 100%;
	}*/
	/*.image-loyalty-item{
		height: 100%;
	}*/
	.text-align-end-loyalty{
		text-align: end;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.icon-loyalty-label-info{
			margin-left: 14px;
		}
	}
	.text-align-start-loyalty{
		text-align: start;
	}
	.text-align-center-loyalty{
		text-align: center;
		display: flex;
		align-items: center;
		.icon-loyalty-label-info{
			margin-left: 14px;
		}
	}
	.image-content-asset{
		height: auto;
		width: 100%;
		@include breakpoint(small only) {
			margin-bottom: 20px;
		}
	}
	.padding-left-contentasset{
		padding-left: 15px;
		/*.large-4{
			padding-right: 45px;
		}
		.small-4{
			padding-right: 35px;

		}
		.medium-4{
			padding-right: 35px;
		}*/
		@include breakpoint(medium down) {
			padding-left: 0px;
		}
	}
	.container-contentasset-info{
		margin-top:20px;
		margin-bottom: 100px;
		@include breakpoint(medium down) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.container-advantage-loyalty > div:first-child {
		height: 100%;
	}
	.container-advantage-loyalty{
		.advantage-loyalty-bar{
			margin-top: 25px;
			margin-bottom: 15px;
			height: 2px;
			background-color: #FB748D;
		}
	}
	.container-icon-loyalty{
		display: flex;
		//align-items: center;
		//padding-top: 8px;
		//padding-bottom: 8px;
		color: #000;
		font-size: 13px;
		font-family: Sofia Pro;
		font-weight: 300;
		line-height: 17.6px;
		@include breakpoint(medium down) {
			align-items: flex-start;
		}
		/*@include breakpoint(medium down) {
			align-items: flex-start;
		}
		.label-icon-asset{
			padding-left: 7px;
			@include breakpoint(medium down) {
				height: 70px;
				width: 70%;
			}
		}*/
		.label-icon-asset{
			padding-left: 7px;
			height: 70px;
			display: flex;
			//align-items: center;
			align-items: flex-start;
			padding-top: 3px;
			@include breakpoint(1200 down) {
				display: flex;
				align-items: flex-start;
			}
			@include breakpoint(small only) {
				display: block;
				height: 70px;
				width: 70%;
			}
		}
		.container-icon-loyalty-div{
			display: flex;
			align-items: center;
			justify-content: space-around;
			height: 36px;
			width: 36px;
			padding-left: 0px !important;
			.icon-loyalty{
				/*height: 22px;
                width: 20px;*/
			}
		}

	}
	.container-icon-loyalty > div:first-child {
		padding-left: 10px;
	}
	.container-cta-left{
		@include breakpoint(medium down) {
			padding-left: 20px;
			padding-right: 20px;
			justify-content: space-between;
			margin-bottom: 10px;
		}
	}
	.loyalty-label-nextlevel{
		color: #000;
		text-align: center;
		font-size: 20px;
		font-family: Sofia Pro;
		font-weight: 500;
		line-height: 17.6px;
	}
	.loyalty-label-nextlevel-left{
		color: #000;
		font-size: 20px;
		font-family: Sofia Pro;
		font-weight: 300;
		line-height: 17.6px;
	}
	.loyalty-weight-300{
		color: #000;
		font-size: 13px;
		font-family: Sofia Pro;
		font-weight: 300;
		line-height: 17.6px;
	}
	.label-threshold{
		color: #000;
		font-size: 18px;
		font-family: Sofia Pro;
		font-style: normal;
		font-weight: 500;
		line-height: 17.6px;
		margin-bottom: 20px;
		@include breakpoint(medium down) {
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}
    .advantage-loyalty-bar + div{
		margin-bottom: 15px;
	}
}
.container-slot-bottom{
	margin-top: 14px;
	.container-content-slot-loyalty{
		width: 100%;
		background: #FCBFB3;
		padding-left: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		.container-slot-left-image{
			display: flex;
			padding-right: 16px;
			align-items: center;
			@include breakpoint(medium down) {
				flex-direction: column;
				padding-right: 10px;
			}
		}
		.icon-loyalty-content-slot{
			width: 22px;
			height: 24px;
			flex-shrink: 0;
		}
		.label-content-slot-text{
			color: #000;
			font-size: 24px;
			font-family: Sofia Pro;
			font-weight: 500;
			line-height: 17.6px;
			padding-left: 10px;
			@include breakpoint(medium down) {
				padding-left: 0px;
				padding-top: 5px;
			}
		}
		.label-content-slot-title{
			color: #000;
			font-size: 13px;
			font-family: Sofia Pro;
			font-weight: 500;
			line-height: 17.6px;
		}
		.label-content-slot-body{
			color: #000;
			font-size: 13px;
			font-family: Sofia Pro;
			font-weight: 300;
			line-height: 17.6px;
		}
	}
	.loyalty-info-image{
		width: 20px;
		height: 20px;
	}

}
.loyalty-tooltip {
	position: relative;
	display: inline-block;
}

.loyalty-tooltip .loyalty-tooltip-text {
	visibility: hidden;
	width: 200px;
	background-color: #000;
	//border-radius: 6px;
	padding: 12px;
	position: absolute;
	z-index: 1;
	bottom: 115%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	transition: opacity 0.3s;
	color: #FFF;
	text-align: start;
	font-size: 13px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 14.6px;
	@include breakpoint(medium down) {
		left: -140%;
		width: auto;
	}
}

.loyalty-tooltip .loyalty-tooltip-arrow {
	position: absolute;
	bottom: -8px;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #000;
	@include breakpoint(medium down) {
		transform: translateX(145%);
	}
}

.loyalty-tooltip:hover .loyalty-tooltip-text {
	visibility: visible;
	opacity: 1;
}


.my-account-container{
	margin-top: 35px;
}
.container-recap-loyalty{
	margin-top: 26px;
	@include breakpoint(medium down) {
		margin-top: 0px;
	}
	.container-label-loyaltyfour{
		display: flex;
		color: #000;
		align-items: normal;
		font-size: 16px;
		font-family: Sofia Pro;
		font-style: normal;
		font-weight: 500;
		line-height: 17.6px;
		.level-completed-paragraph{
			color: #000;
			font-family: Sofia Pro;
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
			line-height: 17.6px;
		}

	}
	.recap-loyalty{
		height: 116px;
		@include breakpoint(medium down) {
			height: auto;
		}
		.main-form-group{
			@include breakpoint(medium down) {
				padding:0px;
				display: flex;
				justify-content: space-around;
				//margin-bottom: 15px;
			}
		}
		.large-3.small-5.loyalty-logo-container.padding-component{
			padding-right: 0px;
			padding-left: 15px;
		}
		.loyalty-logo-container{
			text-align: center;
			//height: 86px;
			background-color: #F2DFD6;
			display: flex;
			align-items: center;
			justify-content: space-around;
			@include breakpoint(medium down) {
				justify-content: space-between;
			}
			.loyalty-left-padding{
				text-align: start;
				padding-left: 5px;
			}
		}
		.padding-component{
			padding-top: 17px;
			padding-bottom: 17px;
			padding-right: 26px;
			padding-left: 26px;
			@include breakpoint(medium down) {
				padding-right: 5px;
				padding-left: 10px
			}
		}
		.container-recap-right{
			background-color: #FCBFB3;
		}
		.loyalty-label-text{
			color: #000;
			font-size: 15px;
			font-family: Sofia Pro;
			font-style: normal;
			font-weight: 500;
			line-height: 17.6px;
			@include breakpoint(medium down) {
				//padding-left: 15px;
				text-align: start;
			}
		}
		.loyalty-label-text-mobile{
			@include breakpoint(medium down) {
				padding-left: 15px;
			}
		}

		.loyalty-logo-image{
			width: 54px;
			height: 34px;
			//padding: 15px;
		}
		.loyalty-name-info{
			background-color: #FB748D;
			height: 40%;
			padding: 10px;
			@include breakpoint(medium down) {
				height: 100%;
				display: flex;
				flex-direction: column;
				padding-bottom: 0px;
			}
		}
		.loyalty-container-progressbar{
			background-color: #FCBFB3;
			height: 100%;
			@include breakpoint(medium down) {
				padding: 10px;
				padding-right: 26px;
				padding-left: 26px;
				padding-top: 25px;
				padding-bottom: 25px;
			}
			.font-size-13{
				color: #000;
				font-family: Sofia Pro;
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				line-height: 17.6px;
			}
		}
		.loyalty-text-center{
			text-align: center;
			width: 160px;
		}
		.loyalty-cta-right{
			margin-left: 15px;
			@include breakpoint(medium down) {
				margin-left: 0px;
			}
		}
		.loyalty-cta-container{

			.loyalty-cta{
				border: 0;
				background-color: #F2DFD6;
				font-family: 'Sofia Pro';
				font-weight: 300;
				font-size: 20px;
				color: $black;
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}


			.loyalty-text-paddingleft{
				padding-left: 35px;
			}
		}
		.progress-loyalty-bar{
			margin-top: 0px;
			@include breakpoint(medium down) {
				margin-top: 10px;
			}
			.progress-bar{
				display: flex;
				border-radius: 0;
				.loyalty-progress-start{
					background-color: #E60C68;
				}
				.loyalty-progress-pending{
					background-color: #FB748D;
				}
			}
		}
		.container-amount{
			display: flex;
			justify-content: space-between;
			font-size: 13px;
		}
		.container-color-amount{
			display: flex;
			align-items: center;
			.loyalty-color-text{
				margin-right: 8px;

			}
			.loyalty-color-amount{
				background-color: #E60C68;
				width: 10px;
				height: 10px;
				border-radius: 100%;
			}
			.loyalty-color-pending{
				background-color: #FB748D;
				width: 10px;
				height: 10px;
				border-radius: 100%;
			}
		}
		.padding-left-loyalty{
			padding-left: 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include breakpoint(medium down) {
				padding-left: 0px;
			}
		}
		.loyalty-expiration-container{
			display: flex;
			align-items: center;
			justify-content: space-around;
			@include breakpoint(medium down) {
				justify-content: space-between;
				margin-top: 10px;
				justify-content: flex-end;
			}

		}
		.container-loyalty-customerservice{
			color: white;
			background-color: #E60C68;
			height: 20%;
			padding-left: 15px;
			padding-right: 40px;
			align-items: center;
		}
		.height-loyalty-four{
			height: 30%;
			@include breakpoint(medium down) {
				height: 100%;
			}
		}
		.height-loyalty-five{
			height: 50%;
		}
		.loyalty-big-container{
			height: 205px;
		}
		.container-image-loyalty{
			height: 384px;
			@include breakpoint(medium down) {
				height: 225px;
			}
		}
		.container-image-loyalty > div:first-child {
			height: 100%;
		}
		.image-loyalty-item{
			height: 100%;
		}
		.text-align-end-loyalty{
			text-align: end;
		}
		.text-align-start-loyalty{
			text-align: start;
		}
		.text-align-center-loyalty{
			text-align: center;
		}
		.image-content-asset{
			height: 100%;
			width: 100%;
		}
		.padding-left-contentasset{
			padding-left: 15px;
			/*.large-4{
				padding-right: 45px;
			}
			.small-4{
				padding-right: 35px;

			}
			.medium-4{
				padding-right: 35px;
			}*/
			@include breakpoint(medium down) {
				padding-left: 0px;
			}
		}
		.container-contentasset-info{
			margin-top:20px;
			margin-bottom: 100px;
			@include breakpoint(medium down) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		.container-advantage-loyalty > div:first-child {
			height: 100%;
		}
		.container-advantage-loyalty{
			.advantage-loyalty-bar{
				margin-top: 25px;
				margin-bottom: 15px;
				height: 2px;
				background-color: #FB748D;
			}
		}
		.container-icon-loyalty{
			display: flex;
			align-items: center;
			padding-top: 8px;
			padding-bottom: 8px;
			color: #000;
			font-size: 15px;
			font-family: Sofia Pro;
			font-weight: 300;
			line-height: 17.6px;
			.label-icon-asset{
				padding-left: 7px;
			}
			.container-icon-loyalty-div{
				display: flex;
				align-items: center;
				justify-content: space-around;
				height: 36px;
				width: 36px;
				padding-left: 0px !important;
				.icon-loyalty{
					height: 22px;
					width: 20px;
				}
			}

		}
		.container-icon-loyalty > div:first-child {
			padding-left: 10px;
		}
		.container-cta-left{
			@include breakpoint(medium down) {
				padding-left: 20px;
				padding-right: 20px;
				justify-content: space-between;
				margin-bottom: 10px;
			}
		}
		.loyalty-label-nextlevel{
			color: #000;
			text-align: center;
			font-size: 20px;
			font-family: Sofia Pro;
			font-weight: 500;
			line-height: 17.6px;
		}
		.loyalty-label-nextlevel-left{
			color: #000;
			font-size: 20px;
			font-family: Sofia Pro;
			font-weight: 300;
			line-height: 17.6px;
		}
		.loyalty-weight-300{
			color: #000;
			font-size: 13px;
			font-family: Sofia Pro;
			font-weight: 300;
			line-height: 17.6px;
		}
		.label-threshold{
			color: #000;
			font-size: 20px;
			font-family: Sofia Pro;
			font-style: normal;
			font-weight: 500;
			line-height: 17.6px;
			margin-bottom: 37px;
			@include breakpoint(medium down) {
				margin-top: 10px;
				margin-bottom: 25px;
			}
		}
	}
}
.small-2.medium-2.align-center.loyalty-expiration-container.loyalty-weight-300{
	justify-content: space-around;
	margin-top: 0px;
	@include breakpoint(medium down) {
		justify-content: flex-end;
	}
}
.small-12.medium-12.loyalty-logo-container.padding-component{
	border-top: 2px solid #DBB19F;
}
.link-loyalty-details{
	color: #000;
	font-size: 13px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 14.6px;
	text-decoration: underline;
	text-decoration-color: grey;
	text-underline-offset: 5px;
}
.container-mobile-logo-loyalty{
	display: flex;
}
.loyalty-title-account-side{
	color: #000;
	font-size: 34px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 17.6px;
	@include breakpoint(medium down) {
		color: #000;
		font-family: Sofia Pro;
		font-size: 32px;
		font-style: normal;
		font-weight: 500;
		line-height: 50px;
	}
}
.container-name-lastname{
	@include breakpoint(medium down) {
		display: flex;
		flex-direction: column;
		font-size: 18px;
		line-height: 1.2;
	}
}
.font-size-18{
	font-size: 18px !important;
}
.loyalty-cta-modify{
	color: #000;
	font-size: 16px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 23.6px;
	text-decoration-line: underline;
	text-underline-offset: 4px;
}
.container-label-loyaltyfour{
	display: flex;
	align-items: center;
	color: #000;
	font-size: 13px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 17.6px;
}

.container-level-completed{
	display: flex;
	align-items: center;
	.container-level-completed-label{
		padding-left: 15px;
		.level-completed-title{
			color: #000;
			font-size: 13px;
			font-family: Sofia Pro;
			font-style: normal;
			font-weight: 400;
			line-height: 17.6px;
			&.russian {
				line-height: 16.6px;
			}
		}
		.level-completed-paragraph{
			color: #000;
			font-family: Sofia Pro;
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
			line-height: 14.6px;
		}
		.level-completed-subtitle{
			color: #000;
			font-size: 16px;
			font-family: Sofia Pro;
			font-style: normal;
			font-weight: 500;
			line-height: 17.6px;
		}
	}
}
.container-customer-service-mobile{
	height: 68px;
	background: #E60C68;
	color: #FFF;
	padding: 12px 0px 12px 27px;

	.customer-service-mobile-label-left{
		font-family: Sofia Pro;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 23.6px;
	}
	.customer-service-mobile-label-left{
		font-family: Sofia Pro;
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 23.6px;
	}
}
.container-detail-completed{
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	justify-content: space-around;
}
.grid-x-small{
	@include breakpoint(medium down) {
		display: flex;
		flex-flow: row wrap;
	}
	.large-50{
		@include breakpoint(medium down) {
			width: 50%;
		}
	}
}
.container-expiration-black{
	@include breakpoint(medium down) {
		text-align: center;
	}
}
.uppercase-letter{
	text-transform: uppercase;
}
.fontweight-bold{
	font-weight: bold;
}
.mobile-padding-four{
	padding-top: 26px;
	padding-bottom: 26px;
}

.font-size-16-label{
	font-size: 16px!important;
}
.font-size-15-label{
	font-size: 15px!important;
}
.font-size-13-label{
	font-size: 13px!important;
}