.my-account-container {
	//max-width: 1100px;
	padding-top: 60px;
	padding-bottom: 20px;
	//padding-left: 20px;
	//padding-right: 20px;

	@include breakpoint(medium down) {
		padding: 0 0 20px;
	}

	&:not(.nav-render) {
		.my-account-side-nav {
			@include breakpoint(medium down) {
				display: none;
			}
		}
	}

	> .grid-x {
		@include breakpoint(medium down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.my-account-side-nav,
	.my-account-side-content {
		@include breakpoint(medium down) {
			width: 100%;
			margin: 0;
		}
	}

	.unregister-view {
		@include breakpoint(medium down) {
			flex-direction: column-reverse;
			padding-top: 20px;
		}

		.my-account-side-nav {
			@include breakpoint(medium down) {
				margin-top: 40px;
				padding-left: 20px;
				padding-right: 20px;
			}

			@include breakpoint(small only) {
				margin-top: 20px;
				padding-left: 0;
				padding-right: 0;
			}

			&:not(.not-small-border) {
				@include breakpoint(small only) {
					border-top: 8px solid #f7f7f7;
					padding-top: 20px;
				}
			}
		}
	}

	.dashboard-nav {
		margin-bottom: 0;
	}

	.alert {
		background-color: #f7f7f7;
		margin-bottom: 30px;
		padding: 20px;

		&.error {
			border-bottom: 4px solid #C82828;
		}

		&.success {
			border-bottom: 4px solid #7ED321;
		}

		.title {
			font-size: 14px;
			text-transform: uppercase;
			color: #000;
		}

		.info {
			font-size: 14px;
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
}

.dashboard-nav {
	> li {
		min-height: 58px;
		font-size: 16px;
		line-height: 1;

		@include breakpoint(medium down) {
			border-top: 8px solid #F7F7F7;
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid #CCCCCC;

			@include breakpoint(medium down) {
				border-bottom: 0;
			}
		}

		&:last-of-type {
			@include breakpoint(medium down) {
				border-bottom: 8px solid #f7f7f7;
			}
		}

		a {
			display: block;
			font-weight: 500;
			line-height: 1;
			background-color: #F7F7F7;
			padding: 21px;
			transition: color 0.3s ease, background-color 0.3s ease;

			@include breakpoint(medium down) {
				position: relative;
				font-size: 16px;
				background-color: white;
				color: #000;
			}

			&::after {
				@include breakpoint(medium down) {
					content: '';
					position: absolute;
					top: 50%;
					right: 22px;
					@include arrow(10px, 10px, black, 2px);
					transform: rotate3d(0, 0, 1, -45deg);
					margin-top: -5px;
				}
			}

			&:hover {
				background-color: #F2DFD6;

				@include breakpoint(medium down) {
					background-color: white;
				}
			}

			&.active {
				color: #000;
				border-left: 4px solid #DBB19F;
				background-color: #F2DFD6;

				@include breakpoint(medium down) {
					background-color: white;
					border-left: 0;
				}
			}
		}
	}
}

.my-account-side-content {
	.empty-msg,
	.info-msg {
		font-size: 16px;
		line-height: 1.6;
		margin-top: 10px;
		margin-bottom: 0;
	}

	.button-control-empty {
		width: 100%;
		max-width: 340px;
		margin-top: 20px;

		@include breakpoint(medium down) {
			max-width: 355px;
		}

		@include breakpoint(small only) {
			width: 100%;
			max-width: none;
		}
	}
}

.back-to-dashboard {
	position: relative;
	width: 100%;
	border-bottom: 8px solid #f7f7f7;
	margin-bottom: 20px;

	.arrow {
		position: absolute;
		top: 50%;
		left: 22px;
		@include arrow(10px, 10px, black, 2px);
		transform: rotate3d(0, 0, 1, 135deg);
	}

	.cta {
		display: block;
		font-size: 16px;
		text-align: center;
		font-weight: 500;
		line-height: 1;
		color: #000;
		padding: 20px;
	}
}

.side-nav-unregister {
	background-color: #F2DFD6;
	padding: 20px;

	.title-box {
		font-size: 20px;
		line-height: 1.15;
		margin-bottom: 0;
	}

	.unregister-info {
		font-size: 14px;
		margin-top: 10px;

		.bull {
			margin-right: 5px;
		}

		ul.default-type {
			list-style-type: disc;
		}
	}

	.row-actions {
		margin-top: 20px;

		.button {
			margin-bottom: 0;
		}
	}
}

.side-email-unregister {
	background-color: #f7f7f7;
	padding: 20px;
	margin-bottom: 20px;

	@include breakpoint(medium down) {
		margin-bottom: 0;
	}

	.title {
		font-size: 14px;
		font-weight: 300;
	}

	.email {
		font-weight: 500;
		color: #000;
	}
}

.new-password-modal,
.new-email-modal {
	.modal-header {
		@include breakpoint(medium down) {
			justify-content: flex-end;
		}
	}

	.modal-wrap {
		@include breakpoint(medium down) {
			max-width: 320px;
			margin-left: auto;
			margin-right: auto;
			padding-top: 40px;
		}
	}

	.title {
		font-size: 20px;

		> p {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}

	.info-description {
		font-weight: 500;
		color: #000;

		@include breakpoint(medium down) {
			font-weight: 300;
			text-align: left;
		}
	}

	.row-actions {
		margin-top: 20px;
	}
}
