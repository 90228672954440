.order-history-content {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.info-sub-text {
		margin-top: 10px;

		@include breakpoint(medium down) {
			max-width: 500px;
		}
	}

	.alert {
		@include breakpoint(medium down) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	&.order-track-content {
		.info-sub-text {
			@include breakpoint(medium down) {
				max-width: none;
			}
		}
	}
}

.order-history-details {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.form-group-track {
	@include breakpoint(small only) {
		display: block;
	}

	.row-input {
		width: 100%;
		margin-right: 40px;

		@include breakpoint(medium down) {
			margin-right: 20px;
		}

		.form-control {
			width: 100%;
			min-height: 44px;
		}
	}

	.row-actions {
		margin-top: 25px;

		.button {
			width: 340px;
			margin-bottom: 0;
			padding: 14px 30px;

			@include breakpoint(small only) {
				width: 100%;
			}
		}
	}
}

.table-order-history {
	margin-top: 20px;
}

.order-list {
	position: relative;

	&::after {
		content: '';
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease, visibility 0.3s ease;
	}

	&.loading {
		&::after {
			pointer-events: auto;
			visibility: visible;
			opacity: 1;
		}

		.spinner {
			visibility: visible;
			opacity: 1;
		}
	}

	.spinner {
		position: absolute;
		visibility: hidden;
		top: 50%;
		left: 50%;
		margin: -10px;
		opacity: 0;
		transition: opacity 0.3s ease, visibility 0.3s ease;
		z-index: 10;
	}
}

.table-order-render {
	border: 1px solid #ccc;

	@include breakpoint(small only) {
		border: 0;
		margin-left: -20px;
		margin-right: -20px;
	}

	.table-head {
		background-color: #f7f7f7;
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		color: #000;
		border-bottom: 1px solid #ccc;
		padding: 16px;

		@include breakpoint(small only) {
			display: none;
		}
	}

	.table-row {
		font-size: 14px;
		line-height: 1.15;
		padding: 16px;

		@include breakpoint(small only) {
			background-color: #f7f7f7;
			padding: 0 20px 20px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;

			@include breakpoint(small only) {
				border-bottom: 0;
				margin-bottom: 20px;
			}
		}
	}

	.label {
		@include breakpoint(small only) {
			margin-top: 20px;
		}

		&:not(:last-of-type) {
			padding-right: 10px;
		}
	}

	.label-text {
		@include breakpoint(small only) {
			display: block;
			font-size: 16px;
			font-weight: 500;
			color: #000;
			margin-bottom: 10px;
		}
	}

	.status {
		&::before {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			background-color: #ccc;
			border-radius: 50%;
			margin-right: 5px;
		}

		&.in-process {
			&::before {
				background-color: #F5A623;
			}
		}

		&.on-delivery {
			&::before {
				background-color: #4A90E2;
			}
		}

		&.delivered {
			&::before {
				background-color: #7ED321;
			}
		}

		&.deleted {
			&::before {
				background-color: #C82828;
			}
		}
	}

	.view {
		color: #000;

		@include breakpoint(small only) {
			display: none;
		}
	}

	.row-action {
		.button {
			width: 100%;
			margin-bottom: 0;
		}
	}
}

.confirm-details {
	&.order-track {
		.info-sub-text {
			@include breakpoint(small down) {
				display: block;
			}

			.actions {
				@include breakpoint(medium down) {
					display: block;
				}
			}

			.button {
				@include breakpoint(medium down) {
					width: 100%;
				}
			}
		}
	}

	.info-sub-text {
		margin-top: 10px;

		>p {
			width: 100%;

			&:not(.no-gutter) {
				padding-right: 60px;
			}

			@include breakpoint(small only) {
				padding-right: 0;
			}
		}

		.actions {
			@include breakpoint(small only) {
				display: none;
			}
		}

		.button {
			width: 100%;
			padding: 14px 30px;
			margin-bottom: 0;
		}
	}

	.header-info-details {
		font-size: 16px;

		.actions {
			margin-top: 20px;
		}

		.button {
			width: 100%;
			margin-bottom: 0;
		}
	}
}

.table-summary-order-details {
	border: 1px solid #ccc;
	margin-top: 20px;

	@include breakpoint(small only) {
		margin-left: -20px;
		margin-right: -20px;
		border: 0;
		border-top: 8px solid #f7f7f7;
	}

	.table-head {
		line-height: 1.15;
		background-color: #f7f7f7;
		border-bottom: 1px solid #ccc;
		padding: 16px;

		@include breakpoint(small only) {
			background-color: white;
			border-bottom: 0;
			padding: 16px 20px;
		}

		.actions {
			@include breakpoint(small only) {
				display: none;
			}
		}
	}

	.table-body {
		@include breakpoint(small only) {
			border-bottom: 8px solid #f7f7f7;
			margin-bottom: 20px;
		}

		.row-actions {
			padding-left: 20px;
			padding-right: 20px;

			.button {
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}

	.table-footer {
		font-size: 16px;
		line-height: 1.15;
		color: #000;
		background-color: #f7f7f7;
		padding: 16px;

		@include breakpoint(small only) {
			padding: 16px 20px;
		}

		.free-label {
			color: #C82828;
		}
	}

	.product-line-item {
		color: #000;
		border-bottom: 1px solid #ccc;
		padding: 16px;

		@include breakpoint(small only) {
			border-bottom: 0;
			padding: 0 20px 20px;
		}

		.product-detail {
			@include breakpoint(small only) {
				border-top: 1px solid #ccc;
				padding-top: 20px;
			}
		}

		.product-image {
			max-width: 80px;

			img {
				width: 100%;
			}
		}

		.product-info {
			width: calc(100% - 80px);
			line-height: 1.15;
			padding-left: 10px;
		}

		.line-item-code {
			margin-top: 4px;
			margin-bottom: 8px;
		}

		.attr,
		.line-item-qty {
			margin-top: 4px;
		}
	}

	.status {
		line-height: 1.15;
		padding-top: 20px;
	}

	.cart-summary-row {
		.order-summary-label {
			display: block;
			font-size: 20px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 16px;
		}

		&:not(:first-child) {
			margin-top: 16px;
		}
	}

	.items-count {
		font-size: 14px;
		font-weight: 500;
		color: #000;

		@include breakpoint(small only) {
			font-size: 16px;
		}
	}

	.order-discount {
		color: #C82828;
	}

	.pipe {
		margin-left: 4px;
		margin-right: 4px;
	}

	.grand-total {
		font-size: 20px;
		font-weight: 500;
		border-top: 1px solid #ccc;
		margin-top: 16px;
		padding-top: 16px;
	}

	.return-grand-total {
		font-size: 20px;
		font-weight: 500;
	}

	.tax-row {
		font-size: 14px;
		margin-top: 4px;
	}
}

.card-list-orders {
	@include breakpoint(small only) {
		margin-left: -20px;
		margin-right: -20px;
	}

	.card-info {
		font-size: 16px;
		background-color: #f7f7f7;
		margin-top: 20px;
		padding: 16px;
	}
}