.container-landing{
  background-image: url("../images/patternripetibile2.svg");
  @include breakpoint(small only) {
    background-position: center center;
  }
  /*background-repeat: repeat-x;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  background-repeat: repeat-y;*/
}
.container-landing-page{
  padding-right: 250px;
  padding-left: 250px;
  padding-bottom: 100px;
  @include breakpoint(medium down) {
    padding-right: 57px;
    padding-left: 57px;
    padding-bottom: 57px;
  }
  .padding-text-container-loyalty{
    padding-right: 100px;
    padding-left: 100px;
    @include breakpoint(medium down) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  .container-white{
    background-color: white;
  }
  .container-description-page{
    padding-right: 243px;
    padding-left: 243px;
    text-align: center;
    @include breakpoint(medium only) {
      padding: 60px;
    }
    @include breakpoint(small) {
      padding: 0px;
    }
  }
  .landing-title {
    color: #000;
    text-align: center;
    font-family: Sofia Pro;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    //line-height: 16px; /* 26.667% */
    @include breakpoint(medium down) {
      font-size: 40px;
      line-height: 50px; /* 125% */
    }
  }

  .landing-subtitle {
    color: #000;
    text-align: center;
    font-family: Sofia Pro;
    font-size: 16px;
    font-style: normal;
    //font-weight: 500;
    line-height: 30px; /* 187.5% */
  }

  .landing-howitworks-title {
    color: #000;
    text-align: center;
    font-family: Sofia Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.8px; /* 110% */
    margin-bottom: 20px;
  }

  .landing-howitworks-paragraph {
    color: #000;
    text-align: center;
    font-family: Sofia Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25.6px; /* 160.001% */
    margin-bottom: 30px;
  }

  .landing-howitworks-subtitle {
    color: #000;
    font-family: Sofia Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
  }

  .landing-howitworks-underline {
    text-decoration-line: underline;
  }

  .landing-imagecontainer-level {
    width: 618px;
    height: 618px;
    @include breakpoint(medium down) {
      width: 390px;
      height: 752px;
    }
  }
 .landing-imagecontainer-level-img{
   padding: 59px;
   width: 100%;
   //height: 100%;
   @include breakpoint(1200 down) {
     padding: 20px;
   }
 }
  .landing-imagecontainer-background-level-1 {
    background: #FCBFB3;
  }

  .landing-imagecontainer-background-level-2 {
    background: #FAA6A6;
  }

  .landing-imagecontainer-background-level-3 {
    background: #FB748D;
  }

  .landing-imagecontainer-background-level-4 {
    background: #E60C68;
  }

  .landing-logo {
    width: 127px;
    height: 81px;
    @include breakpoint(medium down) {
      width: 75px;
      height: 49px;
    }

  }

  .landing-status-title {
    color: #000;
    font-family: Sofia Pro;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    //line-height: 17.6px; /* 55% */
  }

  .landing-price-range {
    color: #000;
    font-family: Sofia Pro;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 17.6px; /* 88% */
    @include breakpoint(medium down) {
      font-size: 15px;
    }
  }

  .landing-status-subtitle {
    color: #000;
    font-family: Sofia Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2; /* 113% */
  }

  .landing-benefit-icon {
    width: 48px;
    height: 48px;
    @include breakpoint(medium down) {
      width: 34px;
      height: 34px;
    }
  }
  .landing-benefit-icon-desktop{
    display: flex;
    align-items: center;
  }

  .landing-benefit-text {
    color: #000;
    font-family: Sofia Pro;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 17.6px; /* 88% */
    padding-top: 5px;
    padding-bottom: 5px;
    @include breakpoint(medium down) {
      //font-size: 15px;
      line-height: 16.6px; /* 110.667% */
    }
  }
  .container-image-level-landing{
    margin-bottom: 80px;
  }

  .landing-subtitle-margin{
    margin: 30px;
    @include breakpoint(medium down) {
      margin: 20px;
    }
  }
  .margin-bottom-5{
    margin-bottom: 5px;
  }
  .margin-bottom-10{
    margin-bottom: 10px;
  }
  .margin-bottom-20{
    margin-bottom: 20px;
  }
  .margin-bottom-40{
    margin-bottom: 40px;
  }
  .margin-bottom-105{
    margin-bottom: 105px;
  }
  .margin-bottom-155{
    margin-bottom: 155px;
  }
  .margin-bottom-85{
    margin-bottom: 85px;
  }
  .margin-bottom-22{
    margin-bottom: 22px;
  }
  .padding-left-60{
    padding-left: 60px;
    @include breakpoint(medium) {
      padding-left: 30px;
    }
  }
  .container-flex-space-between{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .padding-right-60{
    padding-right: 60px;
    @include breakpoint(medium) {
      padding-right: 30px;
    }
  }
  .margin-top-70{
    margin-top: 70px;
  }
  .margin-top-20{
    margin-top: 20px;
  }
  .container-benefit-label-right{
    display: flex;
    align-items: center;
    //padding-left: 14px
  }
  .container-right-image{
    //padding-left: 95px;
    text-align: end;
    @include breakpoint(medium) {
      //padding-left: 30px;
    }
  }
  .container-benefit-right {
    display: flex;
    flex-wrap: nowrap;
  }
  .container-benifit-left{
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-end;
  }
  .container-benefit-label-left{
    display: flex;
    align-items: center;
    //padding-right: 14px
  }
  .container-slide-mobile-landing{
    padding: 35px 65px 50px 65px;
    @include breakpoint(medium only) {
      padding-left: 205px;
      padding-right: 205px;
    }
  }
  .padding-left-20-landing{
    padding-left: 20px;
  }
  .container-benefit-slide{
    display: flex;
    align-items: center;
    height: 55px;
    //margin-bottom: 10px;
  }
  .container-benefit-slide-icon{
    display: flex;
  }
  .text-align-start-loyalty{
    text-align: start;
  }
  .slide-mobile-paragraph-level{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .icon-loyalty-slide-container{
    display: flex;
    justify-content: flex-end;
    //align-items: flex-end;
  }
}
.container-logo-landing{
  padding-top: 50px;
  @include breakpoint(medium down) {
    padding-top: 30px;
  }
  svg{
    @include breakpoint(medium only) {
      width: 138px;
      height: 36px;
    }
    @include breakpoint(small only) {
      width: 138px;
      height: 36px;
    }
  }
}
.container-mobile-levels{
  padding: 0px;
  .swiper-button-next:after, .swiper-button-prev:after{
    content:"";
    font-size: 0px;
  }
}

.square-container {
  position: relative;
  overflow: hidden;
  //height: 100%;
  padding-bottom: 100%; /* Crea un quadrato impostando il padding in base all'altezza del div */
  @include breakpoint(1200 down) {
    //height: 100%;
  }
}

.square-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Assicura che l'immagine occupi tutto lo spazio disponibile */
  //height: 100%; /* Assicura che l'immagine occupi tutto lo spazio disponibile */
  object-fit: cover; /* L'immagine viene ridimensionata per coprire tutto il contenitore senza deformarsi */
}
.landing-imagecontainer-level-div{
  height: 246px;
  width: 246px;
  @include breakpoint(medium down) {
    height: auto;
    width: 100%;
  }
}
.container-start-shopping{
  margin-top: 10px;
  text-align: center;
}