.wishlist-content {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.go-to-shopping {
		width: 100%;
		max-width: 340px;
		margin-top: 20px;

		@include breakpoint(medium down) {
			margin-bottom: 20px;
		}

		@include breakpoint(small only) {
			max-width: none;
			margin-bottom: 0;
		}
	}

	.wishlist-item-list {
		margin-top: 30px;

		@include breakpoint(small only) {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.wishlist-item-row {
		margin-top: 10px;
		padding: 10px 0 20px;

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;

			@include breakpoint(small only) {
				border-bottom: 8px solid #f7f7f7;
			}
		}
	}
}

.wishlist-item-row {
	.wishlist-row {
		flex-wrap: wrap;
		padding-left: 10px;
		padding-right: 10px;

		@include breakpoint(small only) {
			padding-left: 20px;
			padding-right: 20px;
		}

		&.unavailable {
			opacity: 0.6;
		}
	}

	.side-image {
		max-width: 120px;

		img {
			width: 100%;
		}
	}

	.side-info {
		position: relative;
		width: calc(100% - 120px);
		font-size: 14px;
		line-height: 1;
		color: #000;
		padding-left: 20px;

		.product-link {
			display: inline-block;
			vertical-align: middle;
			color: #000;
		}

		.list {
			margin-top: 5px;
		}

		.price {
			> span {
				display: block;
				margin-top: 4px;
			}

			@include breakpoint(small only) {
				display: none;
			}
		}

		.row-attr {
			margin-top: 10px;
		}

		.availability {
			margin-top: 8px;

			@include breakpoint(small only) {
				display: none;
			}
		}

		.availability-msg {
			margin-top: 10px;
		}

		.row-action {
			margin-top: auto;
			padding-top: 10px;
			padding-bottom: 5px;

			@include breakpoint(small only) {
				display: none;
			}
		}
	}

	.bottom-price {
		width: 100%;
		color: #000;

		> span {
			display: block;
			line-height: 1.15;
			margin-top: 2px;
		}
	}

	.row-action-bottom {
		width: 100%;
		margin-top: 20px;
	}

	.action-link {
		display: inline-block;
		vertical-align: middle;
		color: #000;

		> svg {
			display: inline-block;
			vertical-align: middle;
			margin-right: 4px;
		}

		&:not(:last-of-type) {
			margin-right: 20px;
		}

		&.edit {
			svg {
				width: 14px;
				height: 14px;
			}
		}

		&.add-to-cart {
			svg {
				width: 16px;
				height: 16px;
			}
		}

		.label {
			display: inline-block;
			vertical-align: middle;
			text-decoration: underline;
		}
	}

	.remove-from-wishlist {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 12px;
		height: 12px;

		> svg {
			display: block;
			width: 12px;
			height: 12px;
		}
	}

	.out-of-stock-msg {
		font-size: 14px;
		line-height: 1.15;
		color: #C82828;
		margin-top: 20px;
	}
}
