.table-header {
    text-align: left;
    padding: 10px;
    padding-bottom: 8px;
    background: $whitesmoke;

    h5 {
        margin: 0;
    }
}

.table-container {
    position: relative;

    .table-overflow {
        overflow-x: auto;
    }

    .thr-fixed {
        display: none;
        position: absolute;
        width: 100px;
        top: 0;
        left: 0;
        background: $white;
        padding-top: 6px;
        padding-bottom: 0;
        margin-bottom: 14px;
    }

    .table {
        &[data-col-fixed] {
            padding-top: 6px;
            margin-bottom: 14px;
            min-width: 730px;
        }
        
        &[data-col-width="50"] {
            li:not(.th) {
                width: 50px;
            }   
        }
    
        &[data-col-width="70"] {
            li:not(.th) {
                width: 70px;
            }   
        }
    }

    .tr {
        @include flex;
        @include flex-align(justify, middle);
        
        ul {
            @include flex;
            @include flex-align(justify, middle);
            margin: 0;
        }

        .thr {
            background: $white;
        }
    }

    li {
        line-height: 1.6;
        padding: 10px 0;
        color: $black;

        &.th {
            padding-left: 8px;
            padding-right: 8px;
        }

        &:not(.th) {
           text-align: center;
        } 
    }
}
