.container-icon-thron{
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  filter: invert(1);
  position: absolute;
  bottom: 40px;
  right: 25px;
  z-index: 10;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.2);
  &:hover {
    border-radius: 50%;
    background-color: white;
    img{
      filter: invert(0);
    }
  }
}

.container-video-component{
  position: relative;
}

.thron-opacity-layer {
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.thron-region {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 2;
}

#video-thron {
  display: block;
  height: 100%;
}