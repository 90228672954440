.reveal-overlay {
    overflow-y: inherit;
}

.modal {
    backface-visibility: hidden;
    padding: 0;
    -webkit-overflow-scrolling: touch;

    &.large {
        @include breakpoint(large) {
            max-width: 910px;
        }
    }

    &.small {
        width: 530px;
    }

    &,
    &.tiny,
    &.small,
    &.large {
        max-height: 90%;
        top: 50% !important;
        transform: translate3d(0, calc(-50% - .5px), 0);
        border: 0;

        @include breakpoint(medium down) {
            width: 100%;
            height: 100%;
            max-height: 100%;
            top: 0 !important;
            transform: none;
        }
    }

    &-header {
        @include flex;
        @include flex-align(right, middle);
        padding: rem-calc(18 20);
        margin-bottom: 0;

        @include breakpoint(medium down) {
            @include flex-align(justify);
            background: $whitesmoke;
            padding: rem-calc(15 20);
        }
    }

    &-header-paypal {
        @include flex;
        @include flex-align(right, middle);
        padding: rem-calc(18 20);
        margin-bottom: 0;
    }

    &-content {
        padding: rem-calc(20);

        @include breakpoint(medium only) {
            padding-top: rem-calc(70);
        }
    }

    h1, h4 {
        font-size: rem-calc(18);
        margin: 0;
        display: none;

        @include breakpoint(medium down) {
            display: block;
        }
    }

    h4 {
        &.modal-title {
            font-size: rem-calc(16);
            margin-bottom: 0;
            display: block;
        }
    }

    .modal-content {
        padding: 20px 40px;
    }

    .modal-footer {
        padding: 0 40px 40px 40px;

        button {
            margin: 0;
        }
    }
}

.close-modal {
    @include flex;
    @include flex-align(center, middle);
    right: 0;
    top: auto;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    padding-left: rem-calc(26);
    height: rem-calc(18);
    line-height: rem-calc(20);

    &:active {
        color: #000;
    }

    > p {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.15;
        margin-bottom: 0;
    }

    svg {
        width: rem-calc(16);
        height: rem-calc(16);
        position: absolute;
        top: 0;
        left: 0;
    }
}

.modal-background {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;

  .alert-wrapper {
    background-color: white;
    height: 369px;
    width: 669px;
    display: flex;
    padding: 17px;
    max-width: 80%;
    max-height: 90%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span:first-of-type {
      margin-top: 45px;
      font-weight: 700;
      font-size: 1rem;
      @include breakpoint(small only) {
        margin-top: 28px;
      }
    }

    .cta-wrapper {
      display: flex;
      gap: 16px;
      margin-top: 45px;

      @include breakpoint(small only) {
        flex-direction: column;
        gap: 8px;
        margin: 24px auto 32px;
      }

      .button {
        width: 225px;
        margin: 0;

        &.secondary {
          border: 1px solid black;
          background-color: white;
        }
      }
    }
  }
}