.order-returns-content,
.returns-content {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
	}
}
.form-return-items {
	position: relative;
	margin-top: 20px;


  .not-available{
      margin: 16px;
  }
  &::after {
		content: '';
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease, visibility 0.3s ease;
	}

	&.loading {
		&::after {
			pointer-events: auto;
			visibility: visible;
			opacity: 1;
		}

		.spinner {
			visibility: visible;
			opacity: 1;
		}
	}

	.spinner {
		position: absolute;
		visibility: hidden;
		top: 50%;
		left: 50%;
		margin: -10px;
		opacity: 0;
		transition: opacity 0.3s ease, visibility 0.3s ease;
		z-index: 10;
	}

	.row-actions {
		margin-top: 20px;

		.button {
			max-width: 340px;
			width: 100%;
			margin-bottom: 0;

			@include breakpoint(small only) {
				max-width: none;
			}
		}
	}

	.form-error {
		margin-bottom: 0;
	}
}
.form-return-items.disabled{
  pointer-events: none;
  opacity: 0.4;
}

.table-render-returns {
	border: 1px solid #ccc;

	@include breakpoint(small only) {
		border: 0;
	}

	&.slim {
		position: relative;
		border: 0;

		@include breakpoint(small only) {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;
		}

		&::after {
			content: '';
			visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.6);
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.3s ease, visibility 0.3s ease;
		}

		&.loading {
			&::after {
				pointer-events: auto;
				visibility: visible;
				opacity: 1;
			}
		}

		.row-item {
			border: 1px solid #ccc;
			margin-bottom: 16px;

			@include breakpoint(small only) {
				border-top: 0;
				border-left: 0;
				border-right: 0;
				margin-bottom: 10px;
			}

			&:last-child {
				border-bottom: 1px solid #ccc;

				@include breakpoint(small only) {
					border-bottom: none;
				}
			}
		}
	}

	.table-head {
		font-weight: 500;
		line-height: 1.15;
		background-color: #f7f7f7;
		color: #000;
		border-bottom: 1px solid #ccc;
		padding: 16px;

		@include breakpoint(small only) {
			display: none;
		}
	}

	.row-item {
		padding: 16px;

		@include breakpoint(small only) {
			padding-left: 0;
			padding-right: 0;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #ccc;
		}
	}

	.product-image {
		width: 80px;
	}

	.product-info {
		width: calc(100% - 80px);
		color: #000;
		line-height: 1.15;
		padding-left: 12px;

		.line-item-code {
			margin-top: 4px;

			a {
				pointer-events: none;
				color: #000;
			}
		}

		.line-item-attributes {
			margin-top: 8px;
		}

		.attr {
			margin-top: 4px;
		}
	}

	.product-selection {
		width: 16px;
		margin-right: 12px;

		.boolean-field {
			width: 16px;
			height: 16px;
			margin: 0;
			padding: 0;
		}

		.checkbox-input {
			width: 16px;
			height: 16px;
			padding: 0;
		}
	}

	.product-row {
		width: calc(100% - 16px);
	}

	.side-reason {
		@include breakpoint(small only) {
			display: block;
			margin-top: 20px;
		}
	}

	.reason-select {
		width: 100%;
		max-width: 310px;

		@include breakpoint(small only) {
			margin-top: 16px;
			max-width: none;
		}
	}

	.edit-render {
		@include breakpoint(small only) {
			@include clearfix;
			margin-top: 10px;
		}

		svg {
			width: 13px;
			height: 13px;
			margin-right: 5px;
		}

		.edit {
			font-weight: 300;

			@include breakpoint(small only) {
				float: right;
			}

			&:active {
				color: #000;
			}
		}

		.label {
			text-decoration: underline;
		}
	}
}

.return-items-render {
	display: none;

	&.show {
		display: block;
	}

	.info-return-box {
		font-size: 14px;
		background-color: #F2DFD6;
		color: #555;
		margin-top: 20px;
		padding: 20px;

		@include breakpoint(small only) {
			margin-left: -20px;
			margin-right: -20px;
		}

		.icon-box {
			width: 32px;
			height: 32px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5LjMgKDUxMTY3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkJBVENILTIiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIyNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM5NS4wMDAwMDAsIC0zMzcuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00MTYuMiwzNDkuNiBMNDA3LjgsMzQ1IEw0MTEsMzQzLjIgTDQxOS40LDM0Ny44IEw0MTYuMiwzNDkuNiBaIE00MjAsMzU3LjQgTDQxMS41OTksMzYyLjQgTDQxMS41OTksMzUzLjIgTDQxNS41OTksMzUxIEw0MTUuNTk5LDM1NSBMNDE2LjU5OSwzNTQuNCBMNDE2LjU5OSwzNTAuNiBMNDIwLjA5OSwzNDguNyBMNDIwLjA5OSwzNTcuNCBMNDIwLDM1Ny40IFogTTQxMSwzNTIuNCBMNDAyLjU5OSwzNDcuOCBMNDA2LjcsMzQ1LjUgTDQxNS4wOTksMzUwLjEgTDQxMSwzNTIuNCBaIE00MTAuNSwzNjIuNSBMNDAyLjA5OSwzNTcuNSBMNDAyLjA5OSwzNDguOCBMNDEwLjUsMzUzLjQgTDQxMC41LDM2Mi41IFogTTQwMS41LDM0Ny4yIEw0MDEsMzQ3LjUgTDQwMSwzNTggTDQxMC41LDM2My43IEw0MTEsMzY0IEw0MTEuNSwzNjMuNyBMNDIxLDM1OCBMNDIxLDM0Ny41IEw0MjAuNSwzNDcuMiBMNDExLDM0MiBMNDAxLjUsMzQ3LjIgWiBNNDIzLjU5OSwzNDUgQzQyNS4wOTksMzQ3LjMgNDI1LjksMzUwLjEgNDI1LjksMzUzIEM0MjUuOSwzNjEuMyA0MTkuMiwzNjggNDEwLjksMzY4IEM0MDIuNywzNjggMzk2LDM2MS4zIDM5NiwzNTMgQzM5NiwzNDQuNyA0MDIuNywzMzggNDExLDMzOCBDNDE0LjksMzM4IDQxOC41LDMzOS41IDQyMS4yLDM0MiBMNDE5LDM0MiBMNDE5LDM0MyBMNDIzLDM0MyBMNDIzLDMzOSBMNDIyLDMzOSBMNDIyLDM0MS40IEM0MTkuMDk5LDMzOC43IDQxNS4zLDMzNyA0MTEsMzM3IEM0MDIuMiwzMzcgMzk1LDM0NC4yIDM5NSwzNTMgQzM5NSwzNjEuOCA0MDIuMiwzNjkgNDExLDM2OSBDNDE5LjgsMzY5IDQyNywzNjEuOCA0MjcsMzUzIEM0MjcsMzUwLjEgNDI2LjIsMzQ3LjQgNDI0LjgsMzQ1IEw0MjMuNTk5LDM0NSBaIiBpZD0iaWNvbi1yZXR1cm4iPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=');
			margin-bottom: 8px;
		}

		.title {
			font-size: 16px;
			margin-bottom: 0;
		}

		.description {
			font-size: 14px;
			margin-bottom: 0;
			margin-top: 20px;
		}

		.list {
			margin-top: 20px;
			margin-bottom: 0;
		}
	}

	.table-render-returns {
		margin-top: 20px;

		@include breakpoint(small only) {
			margin-top: 5px;
		}

	}

	.confirm-return {
		border-top: 2px solid #ccc;
		margin-top: 40px;
		padding-top: 30px;
		overflow: hidden;

		@include breakpoint(medium down) {
			border-top: 8px solid #f7f7f7;
			margin-top: 0;
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;
		}

		&.no-border {
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}

		.title {
			font-size: 20px;
			margin-bottom: 0;
		}

		.description {
			margin-top: 5px;
		}

		.row-actions {
			margin-top: 10px;
		}

		.button {
			width: 100%;
			max-width: 340px;
			margin-bottom: 0;

			@include breakpoint(medium down) {
				max-width: none;
			}
		}

        .choice-payment {
            .title {
                font-size: 1.313rem;
                font-weight: 400;
            }

            .drawer {
                border-top: 1px solid $light-gray;
                padding: 20px 0;
                &:first-child {
                    border-top: none;
                }

                .gift-card-used {
                    display: none !important;
                }

                img {
                    position: absolute;
                    right: 0;
                    max-width: 100px;
                }
            }

            @include breakpoint(large only) {
                .payment-options {
                    width: 70%;
                }
            }
        }
	}
}

.order-returns-confirm {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.row-render {
		@include breakpoint(small only) {
			display: block;
		}
	}

	.action-view {
		padding-left: 16px;

		@include breakpoint(small only) {
			margin-top: 20px;
			padding-left: 0;
		}

		.button {
			margin-bottom: 0;

			@include breakpoint(small only) {
				width: 100%;
			}
		}
	}

	.title-render {
		width: 100%;

		@include breakpoint(medium down) {
			max-width: 480px;
		}
	}

	.row-actions {
		margin-top: 20px;

		.button {
			width: 100%;
			max-width: 340px;
			margin-bottom: 0;

			@include breakpoint(small only) {
				max-width: none;
			}
		}
	}
}

.box-confirm-return {
	background-color: #f7f7f7;
	margin-top: 20px;
	padding: 20px;

	@include breakpoint(small only) {
		margin-left: -20px;
		margin-right: -20px;
	}

	.box-title {
		font-size: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 10px;
		padding-bottom: 10px;
	}

	.order-no {
		font-size: 16px;
		color: #000;
	}

	.description {
		font-size: 14px;
		margin-top: 16px;
	}

	.row-action {
		.phone-link,
		.phone,
		.label {
			display: inline-block;
			vertical-align: middle;
			color: #000;
		}

		.phone {
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}
	}
}
