$include-fonts: false;

@import "../setup/settings";
@import "../../../node_modules/foundation-sites/scss/foundation";
@import "pdp/vars";

.customer-care-landing {
  padding-top: 60px;
  padding-bottom: 60px;

  .back-to-main {
    position: relative;
    border-bottom: 10px solid $whitesmoke;
    a {
      display: block;
      text-align: center;
      padding: 15px;
      color: $black;
      font-weight: 500;
      font-size: 1em;
    }

    svg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

  }

  @include breakpoint(small down) {
    padding-top: 0px;
  }

}

.contactus-icon {
  > svg {
    width: 75px;
    height: 75px;
  }
  @include breakpoint(medium down) {
    margin-bottom: -20px;
    margin-left: 20px
  }
}

.customer-care {
  @include breakpoint(medium down) {
    padding: 20px;
  }

  .header-customer {
    > svg {
      width: 75px;
      height: 75px;
      margin-bottom: 20px;
    }
  }
  .content-section {
    border-bottom: 1px solid #d8d8d8;
    padding: 0;
    padding-bottom: 12px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .shipment-type {
      display: flex;
      margin-left: 40px;
      .shipment-price {
        min-width: 50px;
        font-weight: 600;
      }
      .shipment-name {
        margin: 0;
        font-weight: 600;
      }

      & + .accordion {
        .trigger {
          display: inline;
          position: relative;
          height: auto;

          &::after {
            content: "";
            display: none;
          }
        }
        .content {
          padding: 20px 0;
        }
      }
    }

    .show-more-text {
      padding-top: 10px;
      margin-left: 40px;
      border: none;
    }
  }

  .customer-title {
    margin-bottom: 40px;
  }

  .accordion {
    a {
      padding: 0;
      border-bottom: 0;
      text-transform: none;
      font-weight: normal;
    }

    .linkable-section {
      a {
        padding: 0;
        border-bottom: 0;
        text-transform: none;
        font-weight: normal;
      }
    }
    .trigger {
      svg {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }
  }

  .banner,
  .cta-banner {
    padding: 10px;
    background-color: $primary-color;
    font-weight: 500;
  }

  .cta-banner {
    margin-top: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;

    p {
      max-width: 70%;
    }

    @include breakpoint(small down) {
      p {
        max-width: 100%;
      }
    }

    .actions {
      margin-top: 15px;
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-around;
      
      @include breakpoint(small down) {
        a:first-child {
          margin-bottom: 1.6em;
        }
      }

      .cta-link {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $black;

        span {
          text-transform: none;
          color: $darkgrey;
          font-weight: normal;
        }
      }

      @include breakpoint(small down) {
        display: inline-block;
      }

    }
  }

  .linkable-section {
    a.cta-link,
    a.trigger {
      padding: 3px;
      border-bottom: 1px solid $secondary-color;
      text-transform: uppercase;
      font-weight: 500;
      text-decoration: none;
    }
  }

  a.cta-link {
    padding: 3px;
    border-bottom: 1px solid $secondary-color;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
  }

  .table-container .tr .thr {
    background-color: transparent;
  }
}

.customer-care {
  @include breakpoint(medium down) {
    .header-customer {
      padding: 0;
      padding-bottom: 20px;

      .customer-title {
        margin-bottom: 0;
      }
    }

    .accordion {
      .trigger {
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .banner {
      margin: 20px 0;
    }
  }
}

.icon-pre-title {
  svg {
    width: 75px;
    height: 75px;
    margin-bottom: 20px;
  }
}
