.product-tile {
  position: relative;
  padding: 10px 0;

  @include breakpoint(large) {
    &:hover {
      .tile-body {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .image-container {
    margin-bottom: 15px;

    a {
      position: relative;
      min-width: 100%;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
    }

    span {
      display: none;
      width: 100%;
      white-space: normal;
      &:nth-child(1) {
        display: inline-block;
      }
    }

    .tile-image {
      margin: auto;
      width: 100%;
      opacity: 0;
      transition: opacity 0.3s;

      &.lazyloaded {
        opacity: 1;

        ~ .placeholder {
          width: 100%;
          display: none;
        }
      }

      ~ .placeholder {
        width: 100%;
        display: block;
      }
    }

    .tile-image-even,
    .tile-image-hover {
      visibility: hidden;
      position: absolute;
    }
  }

  .price {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    color: $black;

    @include breakpoint(small only) {
      margin-bottom: 0;
    }

    .strike-through {
      text-decoration: line-through;
      padding-right: 10px;
      color: $darkgrey;
    }

    .range.range-discount{
      .label-range-discount{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        margin-right: 5px;
      }
    }
  }

  .tile-label {
    height: 16px;
    width: 100%;
    line-height: 12px;
    padding: 0;
    margin-bottom: 10px;

    @include breakpoint(small only) {
      margin-top: 10px;
      margin-bottom: 0;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: $darkgrey;
      text-transform: uppercase;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        height: 1px;
        background-color: $darkgrey;
        width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        height: 1px;
        background-color: $darkgrey;
        width: 100%;
      }
    }

    &.oos span {
			font-weight: 500;
			color: #C82828;
      &::after,
      &::before {
        display: none;
      }
    }
  }

  .tile-body {
    display: none;

    @include breakpoint(large) {
      display: block;
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      transition: transform 0.4s ease, opacity 0.4s ease, visibility 0.4s ease;
      will-change: opacity, transform;
    }
  }

  .image-composition {
    position: relative;
    overflow: hidden;
    .tile-image-hover {
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: auto;
    }
  }

  .tile-image-hover {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
  }

  &:hover {
    .tile-image-hover {
      @include breakpoint(large) {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.product-tile-title {
  width: 100%;
  padding: 0;

  @include breakpoint(medium) {
    position: relative;
  }

  .pdp-link {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: $black;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;

    @include breakpoint(medium) {
      padding-right: 24px;
    }

    @include breakpoint(small only) {
      margin-bottom: 5px;
    }

    > a {
      color: $black;
    }
  }

  html[lang='ru'] & {
    .pdp-link {
      height: 66px;
    }
  }

  .wishlist {
    position: absolute;
    top: 20px;
    right: 15px;

    @include breakpoint(medium) {
      top: 1px;
      right: 1px;
    }

    .wishlist-icon {
      position: absolute;
      top: 1px;
      right: 1px;
      fill: $black;
    }

    .wishlist-background {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    &.is-in-wishlist {
      .wishlist-background {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.tile-body {
  height: 26px;
  padding: 0;
  font-weight: 500;
  word-spacing: 5px;
  width: 100%;
  position: relative;

  .color-swatches {
    width: 100%;
    @include breakpoint(medium) {
      padding-right: 20px;
    }
  }

  .quickview {
    position: absolute;
    top: 1px;
    right: 1px;
    @include breakpoint(small only) {
      display: none;
    }
  }

  .swatches {
    a {
      display: inline-block;
      vertical-align: middle;
      transition: color 0.3s ease;
    }
  }

  .swatch {
    width: 40px;
    height: 26px;
  }
}

.products-slot-slider-wrapper {
  position: relative;
}

.products-slot-slider {
  overflow: hidden;

  @include breakpoint(large) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .tile-image-even {
    display: none;
  }

  .widget-ctrl {
    justify-content: flex-start;

    @include breakpoint(medium only) {
      display: none;
    }
  }

  .slider {
    height: 400px;
    overflow: hidden;

    &.owl-loaded {
      height: auto;
      overflow: visible;
    }
  }

  .owl-item {
    float: left;
  }

  .owl-dots,
  .owl-nav {
    display: none;
  }
}
