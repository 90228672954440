.product-promo-base {
  display: flex;
  margin-bottom: 100px;

  @include breakpoint (medium down) {
    &.grid-container {
      margin: 0 -20px 50px -20px;
    }

    margin-bottom: 50px;
    display: block;

    &.widget .button.editorial {
      border-bottom: 2px solid #DBB19F;
  
      &::after {
        display: none;
      }
    }
  }

  .button.editorial {
    margin-top: 30px;

    @include breakpoint(small only) {
      &::before,
      &::after {
        display: none;
      }

      text-indent: 0;
      width: unset;
      height: unset;
      border-radius: 0;
      background-color: unset;
      margin-top: 15px;
    }

  }

  &.inverted {
    flex-direction: row-reverse;

    .slot-left {
      padding-right: 0;
      padding-left: 50px;

      @include breakpoint (medium down) {
        padding: 0 50px;
      }
    }

    .product-promo-base__editorial-box {
      margin-right: 0;
      margin-left: 50px;

      @include breakpoint (medium down) {
        margin: 0;
      }
    }

    .small-image {
      padding-left: 0;
      padding-right: 50px;

      @include breakpoint (medium down) {
        padding: 0;
      }
  
      &::before {
        left: unset;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__editorial-box {
    position: relative;
    padding: 32px 20% 50px 48px;
    margin-right: 50px;

    &-content {
      @include breakpoint (medium down) {
        text-align: center;
      }
    }

    @include breakpoint (medium down) {
      margin: 0;
      padding: 32px 0 50px 0;
    }

    @include breakpoint (large) {
      &.pink {
        background-color: $primary-color;
      }
  
      &.transparent {
        background-color: transparent;
      }
    }
  }

  .slot-left {
    padding-right: 50px;
    flex: 1;

    @include breakpoint (medium down) {
      display: none;
    }
  }

  .slot-right {
    flex: 1;

    @include breakpoint (medium down) {
      padding: 0 50px;

      &.pink {
        background: linear-gradient(0deg, $primary-color 75%, white 75%);
      }

      &.no-box {
        background: none;
        padding: 0 50px;
      }
    }
  }

  .small-image {
    padding-left: 50px;
    position: relative;

    @include breakpoint (medium down) {
      padding-left: 0;
      padding-right: 0;

      &::before {
        display: none
      }
    }

    &.pink::before {
      background-color: $primary-color;
    }

    &.transparent {
      background-color: transparent;
    }

    &::before {
      content: "";
      height: 75%;
      width: 50px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .main-image {
    height: 100%;
    padding-bottom: 150px;
  }

  @include breakpoint (medium down) {
    .main-image {
      padding-bottom: 30px;
    }
  }

  .no-box {
    .small-image {
      width: 90%;

      @include breakpoint (medium down) {
        width: 100%;
      }
      &::before {
        display: none;
      }
    }
  }

  // TR-2130
  .slot-right {
    > :nth-child(2) {
      display: none;
    }
  }

  @include breakpoint (medium down) {
    &.main-image-mobile-show {
      .slot-right {
        > :first-child {
          display: none;
        }

        > :nth-child(2) {
          display: block;
        }
      }
    }
  }
}

.component-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--focal-point-x) var(--focal-point-y);
}