.dashboard-login-header {
	display: none;
	background-color: #F2DFD6;
	text-align: center;
	padding: 20px;

	@include breakpoint(medium down) {
		display: block;
	}

	.title {
		font-size: 20px;
		margin-bottom: 0;
	}

	.dashboard-log-in {
		font-size: 14px;
		margin-top: 10px;
		margin-bottom: 0;

		.email {
			font-weight: 500;
			color: #000;
		}
	}

	.dashboard-log-out, .dashboard-go-shopping {
		max-width: 355px;
		width: 100%;
		margin: 5px auto 0;

		.log-out {
			width: 100%;
			margin-bottom: 0;
		}
	}
}

.log-out.button.primary.hollow.loyalty-button-logout{
	border: 0px;
	text-decoration: underline;
	text-underline-offset: 5px;
	font-size: 15px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 14.6px;
	text-decoration-color: grey;
}
.log-out.button.primary.loyalty-button-logout-mobile{
	color: #000;
	text-decoration: underline;
	text-underline-offset: 5px;
	background-color: transparent;
	font-size: 15px;
	font-family: Sofia Pro;
	font-style: normal;
	font-weight: 500;
	line-height: 14.6px;
	text-decoration-color: grey;
}

.my-account-side-content {
	.title {
		font-size: 24px;
		line-height: 1.15;
		margin-bottom: 0;

		@include breakpoint(small only) {
			font-size: 20px;
		}

		&.big {
			font-weight: 500;
			font-size: 42px;
			color: #000;
			margin-bottom: 14px;

			@include breakpoint(small only) {
				font-size: 34px;
			}
		}

		&.small {
			font-size: 20px;
		}
	}

	.dashboard-login-head {
		@include breakpoint(medium down) {
			display: none;
		}
	}

	.dashboard-log-in {
		font-size: 14px;
		line-height: 1.15;
		margin-top: 5px;
		margin-bottom: 0;

		.email {
			font-weight: 500;
			color: #000;
		}
	}

	.dashboard-log-out {
		padding-left: 16px;
	}

	.button {
		&.log-out {
			min-width: 110px;
			margin-bottom: 0;
		}
	}
}

.back-to-shopping-wrapper {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
	.content-asset {
		.link-to-shopping {
			font-weight: 700;
		}
	}
}

.back-to-shopping-mobile-wrapper {
	margin-top: 10px;
	.content-asset {
		.link-to-shopping {
			font-weight: 700;
		}
	}
}

.row-basket-items,
.row-wishlist-items {
	padding-top: 20px;
	overflow: hidden;

	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
		overflow: hidden;
	}

	.product-line-item {
		> .cell {
			&:not(:last-of-type) {
				.line-item {
					@include breakpoint(small only) {
						border-bottom: 1px solid #ccc;
					}
				}
			}
		}
	}

	.line-item {
		flex-wrap: wrap;
		margin-top: 22px;
		margin-bottom: 40px;

		@include breakpoint(small only) {
			margin-bottom: 0;
			padding-bottom: 20px;
		}
	}

	.product-name {
		font-size: 14px;
		margin-bottom: 0;
	}

	.side-image {
		width: 120px;
		max-width: 100px;

		&.unavailable {
			opacity: 0.6;
		}

		img {
			width: 100%;
		}
	}

	.side-info {
		width: calc(100% - 100px);
		color: #000;
		padding-left: 20px;

		&.unavailable {
			opacity: 0.6;
		}

		.price {
			font-size: 14px;
			line-height: 1.15;
			margin-top: 4px;
		}

		.list,
		.sales {
			vertical-align: middle;
		}

		.sales {
			margin-left: 4px;
		}
	}

	.product-attr {
		line-height: 1.15;
		margin-top: 20px;

		.attr {
			&:not(:first-of-type) {
				margin-top: 4px;
			}
		}

		.value {
			font-weight: 500;
		}
	}

	.row-actions {
		margin-top: auto;

		@include breakpoint(medium down) {
			margin-left: 0;
			margin-right: 0;
		}

		.button {
			@include breakpoint(medium down) {
				max-width: 355px;
				margin-left: 0;
				margin-right: 0;
			}

			@include breakpoint(small only) {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	.remove-product,
	.add-to-cart {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		padding-top: 10px;
	}

	.control-button {
		margin-bottom: 0;
	}

	.out-of-stock-msg {
		width: 100%;
		font-size: 14px;
		line-height: 1.15;
		color: #C82828;
		margin-top: 20px;
	}
}

.row-basket-items {
	@include breakpoint(medium down) {
		border-top: 0;
		margin-top: 0;
	}
}

.row-wishlist-items {
	@include breakpoint(medium down) {
		border-top: 0;
		padding-top: 0;
	}

	&.wishlist-render {
		@include breakpoint(medium down) {
			border-top: 8px solid #f7f7f7;
			padding-top: 20px;
		}
	}
}

.row-preferred-items {
	border-top: 2px solid #ccc;
	margin-top: 20px;
	padding-top: 40px;
	padding-bottom: 40px;
	overflow: hidden;

	@include breakpoint(medium down) {
		border-top: 0;
		padding: 0 20px 20px;
	}

	&.preferred-items-render {
		@include breakpoint(medium down) {
			border-top: 8px solid #f7f7f7;
			padding: 20px;
		}
	}

	.title {
		font-size: 20px;
		margin-bottom: 20px;
	}

	.card-body {
		font-size: 16px;
		background-color: #f7f7f7;
		color: #000;
		padding: 20px;
	}

	.customer-name {
		display: block;
		font-size: 16px;
		font-weight: 500;
    }

    .payment-name {
		display: block;
		font-size: 16px;
        font-weight: 500;
        padding-left: 60px;
        position: relative;
        height: 32px;
        margin-bottom: 10px;

        img {
            position: absolute;
            left: 0;
        }
	}

	.row-actions {
		margin-top: auto;
		padding-top: 30px;

		.edit {
			font-size: 14px;
			line-height: 1;
			color: #000;
		}

		.label {
			display: inline-block;
			vertical-align: middle;
			text-decoration: underline;
		}

		svg {
			display: inline-block;
			vertical-align: middle;
			width: 14px;
			height: 14px;
			margin-right: 5px;
		}
	}

	.back-to-shopping {
		text-align: end;
    font-weight: 600;
    text-decoration: underline;
	}
}
