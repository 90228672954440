.payment-content {
    @include breakpoint(medium down) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.card-payment-list {
    .card {
        position: relative;
        color: $black;
        font-size: rem-calc(16);
        border: 1px solid $light-gray;
        background-color: $white;
        padding: 20px;

        .spinner-wrapper {
            display: none;
            position: absolute;
            background-color: rgba(255,255,255, 0.8);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
        }

        .spinner {
            position: absolute;
            top: -33px;
            right: 15px;
        }

        &.loading {
            .spinner-wrapper {
                display: block;
            }
        }

        .card-header {
            padding-left: 60px;
            position: relative;
            height: 32px;
            margin-bottom: 10px;
            font-weight: 500;

            img {
                position: absolute;
                left: 0;
            }
        }

        button {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }

    .payment-instrument-expired {
        color: $error;
    }
}
