.orders-pagination {
    display: table;

    .pagination {
        margin-left: 0;
        margin: rem-calc(10 0 10 0);
        list-style-position: outside;
        line-height: rem-calc(18);
        list-style-type: disc;

        li {
            margin-right: 0.0625rem;
            display: inline-block;
            text-align: center;
            font-size: rem-calc(14);
            font-weight: 500;

            button {
                display: block;
                padding: rem-calc(2 6);
                color: $black;

                &:hover {
                    box-shadow: 0 2px 0 0 $secondary-color;
                }
            }
        }

        .current button {
            padding: rem-calc(2 6);
            box-shadow: 0 2px 0 0 $secondary-color;
            cursor: default;
        }

        .disabled {
            color: $light-gray;
            cursor: default;
        }
    }

    .pagination-ctrl {
        width: 14px;
        height: 15px;
        position: relative;

        .pagination-label {
            cursor: pointer;
            color: $black;
            position: absolute;
            width: 14px;
            height: 19px;
            top: 0;
        }

        .disabled {
            cursor: default;
        }
    }

    .pagination-prev,
    .pagination-next {
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1px);
            z-index: -1;
        }
    }

    .pagination-prev {
        a {
            left: 0;
        }

        &::after {
            @include arrow(7px, 7px, black, 2px, left);
        }
    }

    .pagination-next {
        margin-left: 5px;

        a {
            right: 0;
        }

        &::after {
            @include arrow(7px, 7px, black, 2px, right);
        }
    }

    .ellipsis::after {
        padding: 0.1875rem 0.625rem;
        content: '\2026';
        //color:#0a0a0a;
    }
}