[type='text'], 
[type='password'], 
[type='date'], 
[type='datetime'], 
[type='datetime-local'], 
[type='month'], 
[type='week'], 
[type='email'], 
[type='number'], 
[type='search'], 
[type='tel'], 
[type='time'], 
[type='url'], 
[type='color'], 
textarea {
    height: rem-calc(44);
}

.input-group {
    input {
        border-right: 0;
    }

    button {
        background-color: $primary-color;
        width: 42px;
        @include flex;
        @include flex-align(center, middle);
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        border-right: 1px solid $light-gray;
    }

    svg {
        width: 14px;
        height: 14px;
        fill: $black;
    }
}

fieldset {
    margin-bottom: 20px;
}

legend {
    @extend h5;
    margin-bottom: 20px;
}

.is-invalid-input:not(:focus) {
    background-color: white;
}

.is-valid-input {
    border-color: $success-form;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAICAYAAAAvOAWIAAAABGdBTUEAALGPC/xhBQAAANhJREFUGBljYCAAGm6r8DXcUDIBKWPEp7bhqrbQv//fdzD8/6/FwsjoyARS3PDfgQVkArLGhjvKYv//fd8PVGjKyMi4/x+33GXGSbdV2N/+/LsKqFCOjYvBo1rp/suWm2rSv3/93vOfgUEDaPdqaW2h6HTGs7/BJv//z/j3/38Gg5/fGA83XFZxACo8BFLIxMiwUEfbJBKkEGQr2M2r/ocyX716ds7///8TQIIgwMTEOL1e62420AlAfWgAqJCx4arShLrLiv/rryp2o0lj5zZeVXLGLsPAAAB2nlPNFaD5IgAAAABJRU5ErkJggg==');
    background-size: 10px 7px;
    background-repeat: no-repeat;
    background-position: center right 14px;
}

.parsley-error, .inputError {
    border-color: #C82828 !important;
    background-image: none !important;
}

.parsley-genericCustomValidation, .parsley-required {
    margin-top: $form-spacing * -0.5;
    margin-bottom: $form-spacing;
    font-size: $input-error-font-size;
    font-weight: $input-error-font-weight;
    color: $input-error-color;
}

.password-field-container{
    position: relative;

    .show-pass, .hide-pass{
        position: absolute;
        right: 13px;
        top: 0;
        bottom: 0;
        cursor: pointer;

        svg{
            height: 100%;
        }
    }
}


