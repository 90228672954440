.header-banner {
    @include flex;
    @include flex-align(center, middle);
    background-color: $black;
    color: $white;
    overflow: hidden;

    .header-banner-inner-wrapper {
        width: 100%;
        max-width: 1600px;
        padding-top: 2px;
        padding-bottom: 2px;
        overflow: hidden;

        @include breakpoint(small only) {
            padding-top: 2px;
            padding-bottom: 0;
        }

        @include breakpoint (large) {
            padding-left: 60px;
            padding-right: 60px;
        }

        .header-promotion {
            max-width: 520px;
            height: 25px;
            cursor: default;

            @include breakpoint(small only) {
                max-width: 100%;
            }

            ul.ticker {
                width: 100%;
                height: 25px;
                padding: 0;
                margin: 0 auto;
                border: 0;

                &.has-link {
                    cursor: pointer;
                }

                li {
                    overflow: hidden;
                    list-style-type: none;
                    transition: background-color 0.5s;
                    color: #fff;
                    font-weight: 100;
                    font-size: 14px;
                    line-height: 25px;
                    height: 25px;
                    text-transform: uppercase;
                    text-align: center;
                    padding: 0;
                    margin: 0;

                    a {
                        color: $primary-color;
                        font-weight: 800;
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    @include breakpoint(small only) {
                        font-size: 11px;
                        line-height: 25px;
                    }
                }
              }
        }
    }

    .select-country-cta {
        color: $white;

        svg {
            width: 10px;
            height: 10px;
            padding-top: 2px;
            fill: currentColor;
        }
    }
}

#logo {
    display: flex;
    align-items: center;

    svg {
        width: 176px;
        height: 46px;

        @include breakpoint (medium down) {
            width: 138px;
            height: 36px;
        }
    }
}

header {
    z-index: 100;

    @include breakpoint (large) {
        margin-bottom: 57px;
    }

    &.no-menu {
        @include breakpoint (large) {
            margin-bottom: 20px;
        }
    }
}

.header {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint (large) {
        padding: 20px 60px 0 60px;
    }

    @include breakpoint (medium down) {
        height: 57px;
        border-bottom: 1px solid $primary-color;
        @include flex;
        @include flex-align(center, middle);
        padding: 0 20px;
    }

    .header-grid {
        @include xy-grid;
        @include breakpoint (medium down) {
            width: 100%;
        }

        &.no-wrap {
            flex-flow: nowrap;
        }
    }

    .cell-center {
        @include flex;
        @include flex-align(center, middle);
        @include xy-cell(3 of 5, $gutters: 0);
    }

    .cell-left,
    .cell-right {
        @include xy-cell(1 of 5, $gutters: 0);

        @include breakpoint(medium down) {
            display: flex;
            align-items: center;
        }
    }

    .cell-right {
        display: flex;
        justify-content: flex-end;
    }

    .cell-menu {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    .main-menu {
        @include breakpoint (large) {
            border-bottom: 1px solid $primary-color;
            background-color: white;
        }
    }

    .empty-main-menu{
        @include breakpoint (large) {
            padding-top: 10px;
            border-bottom: 1px solid $primary-color;
        }
    }

    .find-in-botique {
        transition: color 0.3s ease;
        padding-left: 0;

        svg {
            position: relative;
            top: 4px;
            transform: none;
            margin-right: 8px;
        }
    }
}

.sub-header {
    position: relative;
    background-color: $white;
    transition: top 0.8s ease;
    transform: translateZ(0);

    &.is-anchored {
        position: static;
        top: -80px !important;
    }

    &.is-stuck {
        border-bottom: 1px solid $primary-color;

        @include breakpoint(medium down) {
            border-bottom: none;
        }

        .main-menu, .empty-main-menu {
            border-bottom: none;
        }

        .header {
            padding-top: 0;
        }

        .cell-left {
            @include breakpoint (large) {
                display: none;
            }
        }

        .cell-menu {
            position: static;
            // flex: 1 0 auto;

            @include breakpoint(large only) {
                margin-left: 10px;
            }

            @include breakpoint(xlarge) {
                margin-left: 15px;
            }
        }

        .cell-center {
            width: 138px;
            margin-left: 0;
            margin-right: 0;

            @include breakpoint(large) {
                align-items: baseline;
                padding-top: rem-calc(21px);
            }

            @include breakpoint(medium down) {
                @include xy-cell(3 of 5, $gutters: 0);
            }
        }

        .cell-right {
            width: auto;
            align-items: center;

            @include breakpoint(large) {
                flex: 1;
                align-items: baseline;
                padding-top: rem-calc(24.5px);
            }

            @include breakpoint(medium down) {
                @include xy-cell(1 of 5, $gutters: 0);
                min-width: 1px;
            }

            @include breakpoint(xlarge) {
                min-width: 235px;
            }
        }

        .level-1 {
            justify-content: center;
            padding-top: rem-calc(24.5px);

            > li {
                // padding: rem-calc(24.5px) 15px;
                padding: 0 8px rem-calc(24.5px);
            }
        }

        nav {
            position: static;
        }

        #logo {
            height: 36px;

            svg {
                width: 138px;
                height: 36px;
            }
        }

        .mobile-toggle-button {
            &.toggle-mobile-search {
                svg {
                    top: auto;
                }
            }
        }

        .header-toolbar {
            > li {
                @include breakpoint(large only) {
                    margin: 0 1px;
                }
            }
        }
    }
}
