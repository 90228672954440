.editorial-component {
  display: flex;
  margin-bottom: 100px;
  padding: 35px;
  background: linear-gradient(90deg, $primary-color calc(80%), white calc(80%));
  
  @include breakpoint (medium down) {
    padding: 0 10vw;
    margin-bottom: 50px;
    flex-direction: column;
    background: linear-gradient(0deg, $primary-color calc(80%), white calc(80%));
    text-align: center;

    &__container.grid-container {
      padding: 0;
    }

    &.widget .button.editorial {
      border-bottom: 2px solid #DBB19F;
  
      &::after {
        display: none;
      }
    }
  }

  .button.editorial {
    margin-top: 30px;

    @include breakpoint(small only) {
      &::before,
      &::after {
        display: none;
      }

      text-indent: 0;
      width: unset;
      height: unset;
      border-radius: 0;
      background-color: unset;
      margin-top: 15px;
    }

  }

  [data-video] {
    flex: 1;

    @include breakpoint (medium down) {
      margin: 0;
    }
  }

  &__video {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & &__image {
    flex: 1;

    img {
      @include breakpoint (small only) {
        height: 80vw;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    padding-right: 50px;

    @include breakpoint (medium down) {
      padding-right: 0;
    }
  }

  &__body {
    padding-right: 50px;

    @include breakpoint (medium down) {
      padding-right: 0;
    }
  }

  &__editorial-box {
    flex: 1;
    margin: 0 auto;
    padding: 35px 100px 35px 35px;

    @include breakpoint (medium down) {
      order: 2;
      width: 100%;
      padding: 50px 0 30px 0;
    }
  }

}