html {
	-webkit-tap-highlight-color: transparent;
}

html.is-reveal-open,
html.is-reveal-open body {
	position: static;
    min-height: 1px;
}

[tabindex] {
	[data-whatinput="mouse"] &,
	[data-whatinput="touch"] & {
		outline: 0;
	}
}

.no-text {
	font: 0/0 a;
    text-indent: percentage(1);
    white-space: nowrap;
    color: transparent;
    overflow: hidden;
}

.visuallyhidden {
	position: absolute !important;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap;
	border: 0;
}
