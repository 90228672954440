.subscribe-wrap {
    a {
        color: $black;
        text-decoration: underline;
    }

    p:last-of-type {
        font-size: rem-calc(14);
    }

    // button {
    //     width: 100%;
    // }

    .image-wrap {
        @include breakpoint(small only) {
            margin-bottom: rem-calc(20);
        }
    }

    .form-error {
        margin-top: 0;
    }

   .subscribe-checkbox-margin-top {
        margin-top: 7px;
    }

    .newsletterform{
        .email-input-field{
            margin-top: 40px;
        }

        .lang-pref{
            margin-bottom: 30px;
        }

        .privacy-text{
            font-size: 1rem;
            line-height: 1.4rem;
        }

        .recaptcha-info{
            font-size: 0.75rem;
            line-height: 14px;
        }

    }
}

// unsubscribe and postsubmit banner
.newsletter-banner {
    background: $whitesmoke;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;

    @include breakpoint(small only) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &--content {
        margin: 0 auto;
        max-width: rem-calc(520);
        padding: rem-calc(65 10);

        @include breakpoint(small only) {
            padding: rem-calc(30 10);
        }
    }

    p {
        @include breakpoint(medium down) {
            max-width: rem-calc(340);
            margin: 0 auto;
        }

        &:last-of-type {
            margin-bottom: rem-calc(28);
        }
    }

    form {
        margin: 0 auto;
        max-width: rem-calc(340);

        label {
            text-align: left;
        }

        button {
            width: 100%;
        }
    }

    a {
        display: inline-block;
        width: 100%;
        max-width: rem-calc(340);
    }
}
