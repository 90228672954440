.text-component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  margin-bottom: 100px;

  .link-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include breakpoint(small only) {
    padding: 50px 10px;
    margin-bottom: 50px;

  }
  
  &.box-pink {
    background-color: $primary-color;
  }

  &.box-transparent {
    background-color: transparent;
  }

  &__eyelet {
    text-transform: uppercase;
    margin-bottom: 20px;
    .white-text & {
      color: $white;
    }
  }

  &__title {
    margin-bottom: 20px;
    text-align: center;
    .white-text & {
      color: $white;
    }

    @include breakpoint(small only) {
      padding: 0 20vw;
    }
  }

  &__description {
    .white-text & {
      color: $white;
    }
  }

  &.white-text {
    .button.editorial {
      color: $white;
    }
  }

  .button.editorial {
    margin-top: 20px;
    
    @include breakpoint(small only) {
      &::before,
      &::after {
        display: none;
      }

      text-indent: 0;
      width: unset;
      height: unset;
      border-radius: 0;
      background-color: unset;
    }

  }

  @include breakpoint(small only) {
    &.widget .button.editorial {
      border-bottom: 2px solid #DBB19F;
  
      &::after {
        display: none;
      }
    }
  }

}