.button {
    font-size: rem-calc(14);
    font-weight: $button-font-weight;

    &.primary {
        &:disabled {
            color: rgba($white, .6);
        }

        &:hover {
            &:disabled {
                color: rgba($white, .6) !important;
            }
        }

        &.hollow {
            &:hover {
                background-color: $black;
                color: $white;
            }

            &:disabled {
                color: rgba($black, .6) !important;
                background-color: inherit !important;
            }
        }
    }

    &.editorial-simple {
        padding: 2px 0 5px;
        border-bottom: 2px solid $secondary-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $black;
        margin-bottom: 0;
    }

    &.editorial {
        padding: 2px 0 5px;
        border-bottom: 2px solid $secondary-color;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $black;
        margin-bottom: 0;

        @include breakpoint (small only) {
            text-indent: -10000px;
            border: none;
            width: 38px;
            height: 38px;
            background-color: $secondary-color;
            border-radius: 19px;
            position: relative;
            margin: 0;
            padding: 0;
        }

        &:before {
            @include breakpoint (small only) {
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: block;
                height: 9px;
                width: 9px;
                position: absolute;
                vertical-align: top;
                margin-left: -2px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
        }

        &:after {
            @include breakpoint (small only) {
                content: '';
                width: 46px;
                height: 46px;
                border: 2px solid $secondary-color;
                border-radius: 23px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }

    &.no-margin {
        margin: 0;
    }

    &.oauth-facebook {
        background-color: #4167B2;

        &:hover {
            background-color: darken(#4167B2, 20);
        }
    }

    &.oauth-google {
        background-color: #DC4437;

        &:hover {
            background-color: darken(#DC4437, 20);
        }
    }

    &.image {
        padding: 0;
        min-height: rem-calc(45);
        @include flex;
        justify-content: center;
        align-items: center;

        svg {
            margin: 0 10px;
        }
    }

    &.block {
        width: 100%;
    }
}

a {
    &.icon {
        position: relative;
        padding-left: 26px;

        svg {
            position: absolute;
            left: 0;
            height: 14px;
            width: 14px;
            top: 50%;
            margin-top: -7px;
        }
    }
}
