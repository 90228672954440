.native-dropdown {
  select,
  select.hidden {
    display: inline-block;
    opacity: 0;
    position: absolute;
    max-width: 44px;
    max-height: 45px;
    right: 0px;
    top: 0px;
    z-index: 1;
    border: 0;
    background: none !important;
    z-index: 1;
  }

  button {
    background: none;
    border: 0;
    max-width: 60px;
    max-height: 45px;
    padding: 10px;
  }
}