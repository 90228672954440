.login-registration-box {
    //max-width: 940px;
    padding-top: 60px;
    padding-bottom: 60px;
    //padding-left: 0;
    //padding-right: 0;
    overflow: hidden;

    @include breakpoint(medium down) {
        padding-top: 30px;
		padding-left: 20px;
		padding-right: 20px;
	}

	@include breakpoint(small only) {
		padding-top: 24px;
		padding-left: 0;
		padding-right: 0;
        padding-bottom: 0;
	}

    > .grid-x {
        @include breakpoint(medium down) {
            margin-left: -20px;
            margin-right: -20px;
        }

        @include breakpoint(small only) {
            margin: 0;
        }
    }

    .side {
        @include breakpoint(medium down) {
            width: 50%;
            margin: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

	.cell {
		@include breakpoint(small only) {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}

    .side {
		@include breakpoint(small only) {
			padding-left: 20px;
			padding-right: 20px;
		}

		&:first-of-type,
		&:last-of-type {
			@include breakpoint(small only) {
				border-bottom: 8px solid #F7F7F7;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}
		}
	}

    .card {
        max-width: 340px;

        @include breakpoint(small only) {
			max-width: none;
		}

        p {
            @include breakpoint(medium down) {
                margin-top: 20px;
            }
        }
    }

    .boolean-field {
        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
    }

    ul {
        font-size: 16px;
        line-height: 26px;
        margin-left: 20px;

        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
    }

    .button {
        @include breakpoint(medium down) {
            margin-top: 20px;
        }
    }
}

.registration-form-container {
    //max-width: 750px;
    padding-top: 60px;
    padding-bottom: 60px;

    @include breakpoint(small only) {
        padding: 20px;
        padding-bottom: 40px;
    }

    .form-title {
        margin-bottom: 10px;
    }

    p.medium {
        font-size: 14px;
    }

    .input-date {
        &[readonly] {
            background-color: white;
            cursor: default;
        }
    }

    .gender-group {
        .boolean-field {
            @include breakpoint(medium) {
                display: inline-flex;
            }
        }
    }

    .lang-pref {
        @include breakpoint(small only) {
            text-align: left;
        }
    }
}


.personal-info-group {
    #stateLabel select {
        display: block;
        width: 100%;
    }

    .phone-number-wrapper {
        display: flex;
        position: relative;
    }

    .input-phone-prefixes {
        .custom-select__option {
            min-width: 80px;
            border-right: 0px;
        }
    }
    .prefix-value {
        position: absolute;
        left: 80px;
        top: calc(50%);
        transform: translateY(-50%);
        height: calc(100% - 23px);
        padding-left: 10px;
        font-weight: 300;
    }
}

.shipping-address-block {
    .phone-number-wrapper {
        display: flex;
    }
}

.address-add-form{
    .phone-number-wrapper {
        display: flex;

        .custom-select{
            width: unset;
        }

        .address-add-form .form-control{
            border-left: 0px;
        }
    }
}

.input-phone-area {
    border-left: 0px;
}

.container-slot-loyalty-login{
  background-image: url("../images/patternripetibile.svg");
  background-repeat: repeat-x;
  background-size: auto 100%;
  padding-top: 55px;
  padding-bottom: 55px;
  padding-right: 150px;
  padding-left: 150px;
  @include breakpoint(medium down) {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-right: 50px;
    padding-left: 50px;
  }
  .slot-loyalty-login{
    width: 100%;
    height: 100%;
    background: #FB748D;
    padding-top: 30px;
    padding-bottom: 30px;
    .slot-loyalty-login-item-left{
      padding-left: 41px;
      @include breakpoint(medium down) {
        padding-left: 21px;
      }
    }
    .slot-loyalty-login-item-right{
      color: #000;
      font-size: 18px;
      font-family: Sofia Pro;
      font-weight: 300;
      padding-right: 46px;
      //line-height: 22.6px;
      @include breakpoint(medium down) {
        font-size: 13px;
        padding-right: 23px;
        //line-height: 15px;
      }
    }
    .slot-loyalty-login-item{
      display: flex;
      align-items: center;
      .slot-loyalty-login-left{
        display: flex;
        align-items: center;
        .slot-loyalty-login-left-text{
          padding-left: 16px;
          color: #000;
          font-size: 33.28px;
          font-family: Fortescue Pro;
          font-weight: 700;
          //line-height: 0px;
          @include breakpoint(medium down) {
            padding-left: 8px;
            font-size: 15px;
          }
        }
        .slot-loyalty-login-left-icon{
          width: 52.693px;
          height: 33.28px;
          flex-shrink: 0;
        }
      }
    }
  }
}
.create-your-account{
  color: #555;
  font-family: Sofia Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25.6px;
}