.grid-container {
    @include breakpoint (small) {
        padding: 0 10px;
    }

    @include breakpoint (medium) {
        padding: 0 20px;
    }

    @include breakpoint (large) {
        padding: 0 7%;
    }

    &.small-full {
        @include breakpoint (small only) {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
        }
    }

    &.medium-full {
        @include breakpoint (medium only) {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
        }
    }

    &.medium-down-full {
        @include breakpoint (medium down) {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;
        }
    }
}
