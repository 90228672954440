$breakpoints: (
    mobile: 0,
    tablet: 768px,
    desktop: 1024px
);

#cookiebanner {
  font-family: 'Sofia Pro', sans-serif;
  box-sizing: border-box;
  height: 218px;
  position: fixed;
  bottom:0;

  z-index: 2147483645;

  @media (max-width: map-get($breakpoints, desktop)) {
    height: auto;
  }

  #c-main {
    display: flex;
    flex-direction: row;
    height: 100%;

    width:100vw;

    background: #FFFFFF;
    border: 1px solid #DBB19F;
    box-sizing: border-box;

    @media (max-width: map-get($breakpoints, desktop)) {
      flex-direction: column;
    }
  }

  .reject-button {
    float: right;
    position: absolute;
    right: 27.04px;
    top: 27.94px;
    color: #000000;

    display: flex;
    cursor: pointer;

    @media (max-width: map-get($breakpoints, desktop)) {
      right: 22.04px;
      top: 21.94px;
    }

    svg {
      width: 12.02px;
      height: 12.02px;
    }

    .text {
      padding-right: 13.94px;

      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
    }
  }

  #c-left {
    width:69%;

    padding-left: 94px;
    padding-top: 36px;
    margin-right: 45px;

    box-sizing: border-box;

    @media (max-width: map-get($breakpoints, desktop)) {
      padding-top: 56px;
      padding-left: 28px;
      padding-right: 28px;

      width:100%;
    }

    .c-header {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;

      color: #000000;

      @media (max-width: map-get($breakpoints, desktop)) {
        line-height: 26px;
        margin-bottom: 8px;
      }
    }

    .c-message {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;

      color: #555555;

      @media (max-width: map-get($breakpoints, desktop)) {
        font-size: 12px;
        line-height: 18px;

        margin-bottom: 24px;
      }
    }

    a {
      font-style: normal;
      font-weight: 300;
      text-decoration: underline;

      color: #000000;
    }

    a:hover {
      font-weight: bold
    }
  }

  #c-right {
    width: 21%;
    padding-top: 72px;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (max-width: map-get($breakpoints, desktop)) {
      width:100%;

      padding-top: 0px;
      position: relative;
      padding-right: 28px;
      padding-left: 28px;

      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 32px;

      a {
        width: 100%;
      }

      a.left-preferences {
        margin-right: 15px;
      }
    }

    .left-preferences {
      div {
        color: #000000;
        margin-bottom: 16px;

        @media (max-width: map-get($breakpoints, desktop)) {
          margin-bottom: 0px;
        }
      }
    }
  }

  div.c-button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    line-height: 44px;
    height: 44px;

    background-color: rgb(255, 255, 255);
    border: 1px solid #000000;
    color: #000000;
    text-align: center;

    width: 100%;
    box-sizing: border-box;

    &.style2 {
      color: #FFFFFF;
      background: #000000;
    }
  }

  #c-preferences {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 44%;
    max-width: 600px;

    height: 80%;
    max-height: 520px;

    z-index: 2147483647;

    background: #FFFFFF;
    border: 1px solid #DBB19F;

    @media (max-width: map-get($breakpoints, desktop)) {
      width: 100%;
      height: 100%;

      max-height: none;
      max-width: none;

      border: 1px solid #DBB19F;
    }

    .reject-button {
      right: 38.04px;
      top: 37.94px;

      @media (max-width: map-get($breakpoints, desktop)) {
        right: 34.04px;
        top: 37.94px;
      }
    }

    .text {
      padding-top: 54px;
      padding-left: 32px;
      padding-right: 32px;

      @media (max-width: map-get($breakpoints, desktop)) {
        padding-top: 32px;
        padding-left: 28px;
        padding-right: 28px;
      }

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;

        color: #000000;

        margin-bottom: 25px;

        @media (max-width: map-get($breakpoints, desktop)) {
          margin-bottom: 24px;
        }
      }

      /*.consentTo {
        margin-top: 24px;
        margin-bottom: 25px;

        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;

        color: #555555;
      }*/

      .cookieType {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        color: #000000;

        margin-bottom: 25px;

        .cookieName {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;

          cursor: pointer;
        }

        .setPreferenceBox {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;

          text-align: right;

          color: #555555;
          margin-left: auto;

          input[type="checkbox"] {
            margin: 0;
            display: none;

            +label {
              margin: 0px;
            }
          }

          .checkButtons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: map-get($breakpoints, desktop)) {
              margin-right: 5px;
            }

            div {
              width: 25px;
              height: 20px;
              background: #C4C4C4;

              svg {
                position: relative;
                display: block;
                margin: 0;
              }
            }

            .accept {
              width: 25px;
              border-radius: 20px 0px 0px 20px;

              svg {
                width: 10px;
                //height: 6.67px;
                height: 9.5px;

                //top: 7px;
                top: 6.5px;
                left: 9px;

                filter: invert(88%) sepia(100%) saturate(0%) hue-rotate(140deg) brightness(103%) contrast(102%);
              }

              &.selected {
                background: #000000;
              }

              &.disabled {
                opacity: 0.5;
              }
            }

            .reject {
              width: 25px;
              border-radius: 0px 20px 20px 0px;

              margin-left: 1px;

              svg {
                width: 8.01px;
                height: 8.01px;

                top: 5.96px;
                left: 6.96px;

                filter: invert(88%) sepia(100%) saturate(0%) hue-rotate(140deg) brightness(103%) contrast(102%);
              }

              &.selected {
                background: #000000;
              }

              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }

        svg {
          width: 12.72px;
          height: 7.12px;

          margin-left: 6px;
          margin-right: 13px;
        }

        .description {
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;

          color: #555555;
          margin-top: 8px;

          margin-left: 31px;

          width: 78%;

          @media (max-width: map-get($breakpoints, desktop)) {
            width: 100%;

            font-size: 12px;
            line-height: 18px;

            margin-top: 16px;
          }
        }
      }

      .consentButtons {
        position: absolute;
        bottom: 32px;
        padding-left: 32px;
        padding-right: 32px;

        left: 0px;
        right: 0px;

        display: flex;
        justify-content: space-between;

        background: #FFFFFF;

        a {
          width: 100%;
        }

        .left-preferences {
          margin-right: 15px;
        }

        @media (max-width: map-get($breakpoints, desktop)) {
          padding-top: 32px;
          border-top: 1px solid #DBB19F;
        }
      }
    }
  }

  #c-overlay {
    position: fixed;
    top: 0;

    width: 100vw;
    height: 100vh;

    background: #000000;
    opacity: 0.3;

    z-index: 2147483646;
  }
}
