$header-height: 152px;
$hero-height: calc(80vh - 152px);
$hero-height-full: calc(100vh - 152px);

.hero-component {
  width: 100%;
  height: $hero-height;
  min-height: 600px;
  background-position: var(--focal-point-x) var(--focal-point-y);
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;

  .hero-image-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  &.full-viewport {
    height: $hero-height-full;
  }

  &.box-middle-left {
    justify-content: flex-start;
    align-items: center;
    @include breakpoint(small only) {
      justify-content: center;
      align-items: flex-end;
    }
  }

  &.box-middle-right {
    justify-content: flex-end;
    align-items: center;
    @include breakpoint(small only) {
      justify-content: center;
      align-items: flex-end;
    }
  }

  &.box-middle-center {
    justify-content: center;
    align-items: center;

    @include breakpoint(small only) {
      justify-content: center;
      align-items: flex-end;
    }

    .editorial-box {
      text-align: center;
      padding: 50px;

      .hero-component__title {
        padding: 0;
      }
    }

    @include breakpoint (small only) {
      .editorial-box {
        padding: 25px;
      }
    }
  }

  &.box-bottom-center {
    justify-content: center;
    align-items: flex-end;

    .editorial-box {
      text-align: center;
      padding: 50px;

      .hero-component__title {
        padding: 0;
      }
    }
  }


  @include breakpoint(small only) {
    height: 100vw;
    min-height: unset;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 50px;

    //Not applying the margin to hero component when the box is transparent because it doesn't need space below
    &.show-box:not(.transparent) {
      margin-bottom: calc(50% + var(--mb)) !important;
    }

    &.full-viewport {
      height: 100vw;
    }
  }

  &__title {
    padding-right: 50px;

    @include breakpoint (small only) {
      padding: 0;
    }

    &.font-secondary {
      font-weight: 500;
    }
  }

  &__subtitle {
    font-size: 86px;
  }

  .button.editorial {
    margin-top: 30px;

    @include breakpoint(small only) {
      &::before,
      &::after {
        display: none;
      }

      text-indent: 0;
      width: unset;
      height: unset;
      border-radius: 0;
      background-color: unset;
    }

  }

  .editorial-box {
    padding: 64px 40px 64px 60px;
    width: 420px;
    min-height: 25%;

    @include breakpoint(small only) {
      width: 85%;
      padding: 40px 30px;
      transform: translateY(50%);
      text-align: center;
    }

    &.box-pink {
      background-color: $primary-color;
    }

    &.box-white {
      background-color: $white;
      border: 10px solid $primary-color;
    }
  
    &.box-transparent {
      background-color: transparent;

      @include breakpoint(small only) {
        transform: translateY(0);
      }

      h1, h2, a {
        color: $white;
      }
    }
  }

  @include breakpoint(small only) {
    &.widget .button.editorial {
      border-bottom: 2px solid #DBB19F;
  
      &::after {
        display: none;
      }
    }
  }
}