.grecaptcha-badge {
  /*
   * Force reCaptcha badge to be on top of other elements
   * to avoid overlapping with homepage widgets.
   */
  z-index: 99;

  /*
   * Hide reCAPTCHA badge: https://twinset.atlassian.net/browse/TR-1701
   */
  visibility: hidden;
}

.recaptcha-info {
  font-size: 14px;
  line-height: 22px;
  color: #666;

  a {
    color: #000;
    text-decoration: none;
  }

  &.is-smaller {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 295px;
    max-width: 295px;
    margin: 20px auto;
  }
}