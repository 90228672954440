.tab {
    .tab-nav {
        li {
            display: inline-block;
            margin: 0 14px;
            padding: 0 6px;
            border-bottom: 4px solid transparent;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.is-current {
                border-bottom: 4px solid $primary-color;
                
                a {
                    color: $black;
                }
            }
        }
    }

    .tab-content {
        display: none;

        &.is-active {
            display: block;
        }
    }
}