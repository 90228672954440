.breadcrumbs-filters {
	position: relative;
	height: 54px;

	@include clearfix;

	@include breakpoint (small down) {
		&.grid-container {
			padding: 0;
		}
	}

	.breadcrumbs-wrapper {
		background: #fff;
		position: relative;
		z-index: 12;
		width: 100%;
		height: 99%;
		padding-right: 0px;
		white-space: nowrap;
		text-overflow: clip;
		text-align: left;

		&.scrollable-list {
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: none;
			overflow: -moz-scrollbars-none;
			overflow-x: scroll;
			opacity: 0;
		}

		@include breakpoint (small down) {
			max-width: 280px;
		}

		&.align-items-right {
			direction: rtl;
		}

		ul.breadcrumbs-nav {
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			display: flex;

			li {
				display: inline-block;
				padding: 5px 20px 5px 0px;

				@include breakpoint (small down) {
					padding: 5px 10px 5px 20px;
				}

				.breadcrumb-item-wrap {
					margin: 0 auto;
					.custom-select__dropdown {
						button {
							display: block;
						}
					}
					.custom-select button:disabled {
						font-size: unset;
					}
					.custom-select__option--value:disabled {
						background-color: transparent;
					}
				}

				a, select {
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #000;
					font-size: 1rem;
					font-weight: 500;
				}

				a {
					display: block;
					margin: 0 5px;
					line-height: 45px;
				}

				select {
					max-width: 140px;
					direction: ltr;
					&.clear {
						padding: 0 25px 0 0px;
					}
				}
			}
		}
	}
	.select-order-by {
		display: flex;
		align-items: center;
    justify-items: center;
    text-align: center;
    width: 50px;
    height: 54px;
    position: absolute;
    top: 0px;
		right: 20px;

		button {
			margin: 0 0 0 auto;
			padding: 5px 2px 0 5px;
		}
	}
}

.breadcrumb {
	@include clearfix;
	margin-bottom: 0;

	.breadcrumb-item {
		float: left;
		line-height: 1;
		margin-bottom: 6px;

		&:not(:last-of-type) {
			&::after {
				display: inline-block;
				vertical-align: middle;
				content: '/';
				margin-left: 5px;
				margin-right: 5px;
			}
		}

		a {
			display: inline-block;
			vertical-align: middle;
			transition: color 0.3s ease;
		}
	}
}
