.main-menu, .empty-main-menu {
    @include breakpoint (medium  down) {
        display: none;
    }
}

#mobile-pane {
    @include flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    background-color: $white;
    width: 100%;
    z-index: 1000;
    transition: left .3s ease;

    .menu-visible & {
        left: 0;
    }

    .mobile-pane-header,
    .mobile-pane-footer {
        @include flex;

        a {
            width: 33%;
            text-align: center;
            @include flex;
            @include flex-align(center, middle);
            position: relative;

            &.spring {
                display: block;
                flex-grow: 2;
                width: 100%;
            }
        }

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .mobile-pane-header {
        height: 60px;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: $white;
        border-bottom: 1px solid $primary-color;
        // padding: 0 10px;

        a {
            @include breakpoint (medium only) {
                width: 100px;
            }
        }

        .wishlist-render {
            position: relative;

            &.has-wishlist-list {
                .wishlist-list {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .wishlist-list {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                z-index: 10;
            }
        }
    }

    .mobile-pane-footer {
        height: 80px;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: $primary-color;

        a {
            flex-direction: column;
            border-right: 1px solid $secondary-color;

            &:last-child {
               border: none;
            }
        }
    }

    .mobile-pane-content {
        flex-grow: 2;
        overflow-y: auto;
    }
}

.mobile-toggle-button {
    padding: 0 10px 0 0;
    outline: none;
    position: relative;

    svg {
        width: 18px;
        height: 18px;
    }
}

.mobile-menu {
    ul {
        margin: 0;
    }

    a {
        display: block;
        font-weight: 500;
        position: relative;
        color: $black;
        border-bottom: 1px solid $light-gray;
    }

    li {

        > ul.submenu-level-2 {
            display: none;

            ul.submenu-level-3 {
                display: none;
            }
        }

        &.mobile-level-1 {
            a {
                padding: 23px 20px;
                font-size: 20px;
                border-color: $primary-color;

                &.open {
                    svg {
                        transform: rotate(180deg) translateY(50%);
                    }
                }
            }
        }

        &.mobile-level-2 {
            a {
                padding: 16px 20px;
                font-size: 16px;
            }
        }

        &.mobile-level-3 {
            a {
                padding: 16px 20px;
                font-size: 16px;
                font-weight: 300;
                color: $dark-gray;
            }
        }

        &.featured {
            > a {
                color: $featured;
            }
        }
    }

    svg {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    .italic {
        > a {
            font-style: italic !important;
        }
    }
    .bold {
        > a {
            font-weight: bold !important;
        }
    }
}

.menu-visible, .login-popup-visible {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.loyalty-icon-color-account-0 {
    fill: #CF8973;
}
.loyalty-icon-color-account-1 {
    fill: #CF8973;
}
.loyalty-icon-color-account-2 {
    fill: #B3B3B3;
}
.loyalty-icon-color-account-3 {
    fill: #EBD485;
}
.loyalty-icon-color-account-4 {
    fill: #000000;
}
.loyalty-icon-color-account-5 {
    fill: #6EB3DB;
}
.mobile-pane-header{
    .loyalty-icon-text-account {
        color: #000;
        font-size: 13px;
        font-family: Sofia Pro;
        font-weight: 500;
        line-height: 22.4px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-left: 6px;
    }
}
.cell-left{
    .loyalty-icon-account {
        display: flex;
        align-items: center;
        padding-left: 27px;
        position: relative;
    }

    svg {
        width: 18px;
        height: 18px;
    }
}

.loyalty-red-circle-container {
    position: relative;
    display:flex;
}

.loyalty-circle-red-icon-login {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: #E60C68;
    position: absolute;
    top: -4px;
    right: 0px;
}