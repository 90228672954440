.boolean-field {
    display: flex;
    position: relative;
    margin-bottom: 16px;
    padding-right: 20px;

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
    }

    .checkbox-label,
    .radio-label {
        line-height: 1.4;
        color: $dark-gray;
        transition: color 0.3s ease;
    }

    .checkbox-input {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        text-transform: none;
        line-height: 18px;
        margin: 0;

        &::before,
        &::after {
            content: "";
            position: absolute;
        }

        &::before {
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: transparent;
            border: 1px solid $lightgrey;
            transition: all 0.2s;
            cursor: pointer;
        }

        &::after {
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            background: $black;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.2s;
            cursor: pointer;
        }
    }

    .radio-input {
        @extend .checkbox-input;

        &::before {
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }

        &::after {
            top: 5px;
            left: 5px;
            border-radius: 50%;
        }
    }

    input[type="checkbox"]:checked + .checkbox-input::before,
    input[type="radio"]:checked + .radio-input::before {
        border-color: $black;
    }

    // input[type="checkbox"]:focus + .checkbox-input::before,
    // input[type="radio"]:focus + .radio-input::before {
    //     border: 1px solid $secondary-color;
    // }

    input[type="checkbox"]:checked + .checkbox-input::after,
    input[type="radio"]:checked + .radio-input::after {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    input[type="checkbox"]:checked ~ .checkbox-label,
    input[type="radio"]:checked ~ .radio-label {
        color: $black;
    }

    input[type="checkbox"][disabled] + .checkbox-input::before,
    input[type="radio"][disabled] + .radio-input::before {
        opacity: 0.65;
    }

    input[type="checkbox"][disabled] ~ .checkbox-label,
    input[type="radio"][disabled] ~ .radio-label {
        opacity: 0.65;
    }

    &.is-invalid-input {
        background: transparent;

        input[type="checkbox"] + .checkbox-input::before,
        input[type="radio"] + .radio-input::before {
            //background: #faeaea;
            border: 1px solid $error;
        }
    }

    &.is-valid-label {
        background: transparent;

        input[type="checkbox"]:not(:focus) + .checkbox-input::before,
        input[type="radio"]:not(:focus) + .radio-input::before {
            background: $white;
            border: 1px solid $success;
        }
    }

    .errors-list {
        position: absolute;
        bottom: -35px;
        left: 25px;

        .email-signup & {
            bottom: -16px;
            width: 200px;
            left: 0%;
            
        }
    }
}
