.address-book-content {
	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
		overflow: hidden;
	}
}

.address-card-list {
	width: 100%;
	max-width: 720px;
	padding-top: 20px;

	@include breakpoint(medium down) {
		max-width: none;
	}

	@include breakpoint(small only) {
		margin-left: 0;
		margin-right: 0;
	}

	.address-card {
		position: relative;
		font-size: 16px;
		color: #000;
		margin-bottom: 30px;
		padding: 20px;
		transition: background-color 0.3s ease;

		@include breakpoint(small only) {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		}

		&:not(.default) {
			border: 1px solid #c0c1c0;
		}

		&.default {
			background-color: #f7f7f7;
		}

		.card-body {
			word-break: break-all;
		}
	}
}

.row-actions-add-address {
	max-width: 720px;

	@include breakpoint(medium down) {
		max-width: none;
	}
}

.address-card {
	.remove-address {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 12px;
		height: 12px;

		svg {
			width: 12px;
			height: 12px;
		}
	}

	.row-actions {
		font-size: 14px;
		line-height: 16px;
		margin-top: auto;
		padding-top: 40px;
	}

	.card-default-link {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		font-size: 14px;
		font-weight: 500;
		color: #000;
		padding-left: 26px;

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 50%;
		}

		&::before {
			top: 0;
			left: 0;
			width: 16px;
			height: 16px;
			border: 1px solid #000;
		}

		&::after {
			top: 4px;
			left: 4px;
			width: 8px;
			height: 8px;
			background-color: #000;
			opacity: 0;
			transform: scale3d(0, 0, 0);
			transition: transform 0.3s ease, opacity 0.3s ease;
		}

		&.default {
			cursor: default;

			&::after {
				top: 4px;
				left: 4px;
				width: 8px;
				height: 8px;
				background-color: #000;
				opacity: 1;
				transform: scale3d(1, 1, 1);
			}
		}
	}

	.edit-link {
		position: relative;
		color: #000;
		text-decoration: underline;

		svg {
			position: relative;
			top: rem-calc(-2px);
			display: inline-block;
			vertical-align: middle;
			width: rem-calc(14.2px);
			height: 14px;
			margin-right: 6px;
		}
	}
}

.delete-address-modal {
	max-width: 530px;
	border: 1px solid #F2DFD6;

	@include breakpoint(medium down) {
		max-width: none;
		width: 100%;
		border: 0;
	}

	.close-modal {
		font-weight: 300;
		line-height: 16px;

		&:active {
			color: #000;
		}
	}

	.modal-title {
		font-size: 16px;
		margin-bottom: 0;
	}

	.delete-address-button {
		margin-top: 20px;

		@include breakpoint(medium down) {
			max-width: 355px;
			margin-left: auto;
			margin-right: auto;
		}

		@include breakpoint(small only) {
			width: 100%;
			max-width: none;
		}
	}
}