.address-add-form {
	padding-top: 20px;

	.address-form {
		max-width: 720px;

		@include breakpoint(medium down) {
			max-width: none;
		}
	}

	.form-control {
		display: block;
	}

	.form-control-label {
		// &.label-country {
		// 	display: inline-block;
		// 	line-height: 1;
		// }
	}

	.label-right {
		font-weight: 300;
	}

	.custom-select {
		width: 100%;

		&__option,
		&__dropdown {
			display: block;
			width: 100%;
		}

		&__option--value {
			&:disabled {
				height: auto;
				background-color: transparent;
				font-weight: 300;
				line-height: 1;
				color: #000;
				cursor: default;
				padding: 0;
			}
		}
	}

	.select-state {
		width: 100%;
	}

	.select-country {
		display: inline-block;
		width: auto;
		height: auto;
		font-weight: 300;
		border: 0;
		background-image: none;
		padding: 0;
	}

	.save-as-primary-label {
		font-weight: 300;
		padding-top: 5px;

		.checkbox-label {
			line-height: 1.15;
			color: #000;
		}
	}

	.info-add-address {
		font-size: 14px;
	}

	.button-submit {
		width: 100%;
		max-width: 340px;
		margin-top: 5px;

		@include breakpoint(medium down) {
			max-width: 355px;
		}
	}
}
