$primary-font-family: 'Sofia Pro', sans-serif;
$primary-font-family-bold: 'Sofia Pro', sans-serif;
$secondary-font-family: 'Silver South Script', sans-serif;
$tertiary-font-family-italic: 'PlayfairDisplay-Italic', sans-serif;
$tertiary-font-family-bold: 'PlayfairDisplay-Bold', sans-serif;


.font-primary {
    font-family: $primary-font-family;
}

.font-primary-bold {
    font-family: $primary-font-family-bold;
}

.widget {
    .font-primary {
        font-weight: 500;
    }

    .font-primary-bold {
        font-weight: 700;
    }

    h1, h2 {
        font-size: rem-calc(42);

        @include breakpoint(small only) {
            font-size: rem-calc(34);
        }
    }

}

.font-secondary {
    font-family: $secondary-font-family;
}

.font-tertiary-italic {
    font-family: $tertiary-font-family-italic;
    font-weight: 300 !important;
}

.font-tertiary-bold {
    font-family: $tertiary-font-family-bold;
}

h1, h2, h3, h4, h5, h6 {
    color: $black;
    line-height: 1.1em;

    &.underline {
        border-bottom: 1px solid $lightgrey;
        padding-bottom: $header-margin-bottom;
    }
}

h1.font-secondary,
h2.font-secondary {
    font-size: rem-calc(72);

    @include breakpoint(small only) {
        font-size: rem-calc(48);
    }
}



p {
    font-size: rem-calc(16);
    line-height: 1.6em;

    &.small {
        font-size: rem-calc(14);
        line-height: rem-calc(25);
        margin-bottom: 0;
    }
}

.big-text {
    font-size: rem-calc(110);
    line-height: 1.1em;
    color: $primary-color;
    font-weight: 500;

    @include breakpoint(small only) {
        font-size: rem-calc(90);
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 20px;
    }
}

.text-dark {
    color: $black !important;
}

.strike-through {
    text-decoration: line-through;
}
.post-strike-through {
    margin-left: 10px;
}

.span-font-light {
    font-weight: 300;
}

.link-underline {
    text-decoration: underline;
    color: $black;
}

a {
    &.underline {
        text-decoration: underline;
        color: $black;
    }

    &.has-icon {
        position: relative;
        padding-left: 25px;

        svg {
            width: 16px;
            height: 16px;
            fill: $black;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

ul {
    &.disc {
        list-style: disc;
    }

    &.inside {
        list-style-position: inside;
    }
}

.stronger {
    font-weight: bold;
}

@include breakpoint (medium) {
    //MARGIN TOP DESKTOP
    .mt-d-0 {
        margin-top: 0 !important;
    }

    .mt-d-5 {
        margin-top: 5px !important;
    }
    
    .mt-d-10 {
        margin-top: 10px !important;
    }
    
    .mt-d-15 {
        margin-top: 15px !important;
    }
    
    .mt-d-20 {
        margin-top: 20px !important;
    }
    
    .mt-d-25 {
        margin-top: 25px !important;
    }
    
    .mt-d-30 {
        margin-top: 30px !important;
    }
    
    .mt-d-50 {
        margin-top: 50px !important;
    }
    
    .mt-d-75 {
        margin-top: 75px !important;
    }
    
    .mt-d-100 {
        margin-top: 100px !important;
    }
    
    .mt-d-150 {
        margin-top: 150px !important;
    }
    
    .mt-d-200 {
        margin-top: 200px !important;
    }


    //MARGIN BOTTOM DESKTOP

    .mb-d-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-d-5 {
        margin-bottom: 5px !important;
    }
    
    .mb-d-10 {
        margin-bottom: 10px !important;
    }
    
    .mb-d-15 {
        margin-bottom: 15px !important;
    }
    
    .mb-d-20 {
        margin-bottom: 20px !important;
    }
    
    .mb-d-25 {
        margin-bottom: 25px !important;
    }
    
    .mb-d-30 {
        margin-bottom: 30px !important;
    }
    
    .mb-d-50 {
        margin-bottom: 50px !important;
    }
    
    .mb-d-75 {
        margin-bottom: 75px !important;
    }
    
    .mb-d-100 {
        margin-bottom: 100px !important;
    }
    
    .mb-d-150 {
        margin-bottom: 150px !important;
    }
    
    .mb-d-200 {
        margin-bottom: 200px !important;
    }


    //MARGIN LEFT DESKTOP

    .ml-d-0 {
        margin-left: 0 !important;
    }

    .ml-d-5 {
        margin-left: 5px !important;
    }

    .ml-d-10 {
        margin-left: 10px !important;
    }

    .ml-d-15 {
        margin-left: 15px !important;
    }

    .ml-d-20 {
        margin-left: 20px !important;
    }

    .ml-d-25 {
        margin-left: 25px !important;
    }

    .ml-d-30 {
        margin-left: 30px !important;
    }

    .ml-d-50 {
        margin-left: 50px !important;
    }

    .ml-d-75 {
        margin-left: 75px !important;
    }

    .ml-d-100 {
        margin-left: 100px !important;
    }

    .ml-d-150 {
        margin-left: 150px !important;
    }

    .ml-d-200 {
        margin-left: 200px !important;
    }


    //MARGIN RIGHT DESKTOP

    .mr-d-0 {
        margin-right: 0 !important;
    }

    .mr-d-5 {
        margin-right: 5px !important;
    }

    .mr-d-10 {
        margin-right: 10px !important;
    }

    .mr-d-15 {
        margin-right: 15px !important;
    }

    .mr-d-20 {
        margin-right: 20px !important;
    }

    .mr-d-25 {
        margin-right: 25px !important;
    }

    .mr-d-30 {
        margin-right: 30px !important;
    }

    .mr-d-50 {
        margin-right: 50px !important;
    }

    .mr-d-75 {
        margin-right: 75px !important;
    }

    .mr-d-100 {
        margin-right: 100px !important;
    }

    .mr-d-150 {
        margin-right: 150px !important;
    }

    .mr-d-200 {
        margin-right: 200px !important;
    }
}

@include breakpoint (small only) {
    //MARGIN TOP MOBILE
    .mt-m-0 {
        margin-top: 0 !important;
    }

    .mt-m-5 {
        margin-top: 5px !important;
    }
    
    .mt-m-10 {
        margin-top: 10px !important;
    }
    
    .mt-m-15 {
        margin-top: 15px !important;
    }
    
    .mt-m-20 {
        margin-top: 20px !important;
    }
    
    .mt-m-25 {
        margin-top: 25px !important;
    }
    
    .mt-m-30 {
        margin-top: 30px !important;
    }
    
    .mt-m-50 {
        margin-top: 50px !important;
    }
    
    .mt-m-75 {
        margin-top: 75px !important;
    }
    
    .mt-m-100 {
        margin-top: 100px !important;
    }
    
    .mt-m-150 {
        margin-top: 150px !important;
    }
    
    .mt-m-200 {
        margin-top: 200px !important;
    }


    //MARGIN BOTTOM MOBILE

    .mb-m-0 {
        margin-bottom: 0 !important;
    }
    
    .mb-m-5 {
        margin-bottom: 5px !important;
    }
    
    .mb-m-10 {
        margin-bottom: 10px !important;
    }
    
    .mb-m-15 {
        margin-bottom: 15px !important;
    }
    
    .mb-m-20 {
        margin-bottom: 20px !important;
    }
    
    .mb-m-25 {
        margin-bottom: 25px !important;
    }
    
    .mb-m-30 {
        margin-bottom: 30px !important;
    }
    
    .mb-m-50 {
        margin-bottom: 50px !important;
    }
    
    .mb-m-75 {
        margin-bottom: 75px !important;
    }
    
    .mb-m-100 {
        margin-bottom: 100px !important;
    }
    
    .mb-m-150 {
        margin-bottom: 150px !important;
    }
    
    .mb-m-200 {
        margin-bottom: 200px !important;
    }


    //MARGIN LEFT MOBILE

    .ml-m-0 {
        margin-left: 0 !important;
    }

    .ml-m-5 {
        margin-left: 5px !important;
    }

    .ml-m-10 {
        margin-left: 10px !important;
    }

    .ml-m-15 {
        margin-left: 15px !important;
    }

    .ml-m-20 {
        margin-left: 20px !important;
    }

    .ml-m-25 {
        margin-left: 25px !important;
    }

    .ml-m-30 {
        margin-left: 30px !important;
    }

    .ml-m-50 {
        margin-left: 50px !important;
    }

    .ml-m-75 {
        margin-left: 75px !important;
    }

    .ml-m-100 {
        margin-left: 100px !important;
    }

    .ml-m-150 {
        margin-left: 150px !important;
    }

    .ml-m-200 {
        margin-left: 200px !important;
    }


    //MARGIN RIGHT MOBILE

    .mr-m-0 {
        margin-right: 0 !important;
    }

    .mr-m-5 {
        margin-right: 5px !important;
    }

    .mr-m-10 {
        margin-right: 10px !important;
    }

    .mr-m-15 {
        margin-right: 15px !important;
    }

    .mr-m-20 {
        margin-right: 20px !important;
    }

    .mr-m-25 {
        margin-right: 25px !important;
    }

    .mr-m-30 {
        margin-right: 30px !important;
    }

    .mr-m-50 {
        margin-right: 50px !important;
    }

    .mr-m-75 {
        margin-right: 75px !important;
    }

    .mr-m-100 {
        margin-right: 100px !important;
    }

    .mr-m-150 {
        margin-right: 150px !important;
    }

    .mr-m-200 {
        margin-right: 200px !important;
    }
}

