@import "../../../node_modules/tom-select/src/scss/tom-select.scss";
@import "../../../node_modules/intl-tel-input/src/css/intlTelInput";



.phone-number-wrapper {
  .iti__flag {background-image: url("../images/intlTelInput/flags.png");}

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../images/intlTelInput/flags.png");}
  }

  display: flex;
  margin: 0 0 $form-spacing;

  .input-phone-prefixes{
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxNSAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5hcnJvdy1kb3duPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImZvb3Rlci9tb2JpbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yODUuMDAwMDAwLCAtMzEzLjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOTIuMjYxMTY0LCAzMTguMDAwMDAwKSBzY2FsZSgtMSwgMSkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC0yOTIuMjYxMTY0LCAtMzE4LjAwMDAwMCkgIiBwb2ludHM9IjI4OCAzMTIuNTQxMzYxIDI5My40NjE0ODMgMzE4LjAwMjg0NCAyODggMzIzLjQ1ODIwMiAyODkuNTQxMzYxIDMyNSAyOTYuNTIyMzI5IDMxOC4wMDI4NDQgMjg5LjU0MTM2MSAzMTEiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==) !important;
    background-position: right 9px center;
    background-repeat: no-repeat;
    background-size: 9px;

    &.dropdown-active{
      .ts-control{
        border-color: $secondary-color;
      }
    }

    .ts-control{
      @include form-element;
      appearance: none;
      white-space: nowrap;
      text-align: left;
      user-select: none;
      font-weight: 500;
      margin: 0;
      height: rem-calc(44);
      padding: $input-padding 32px $input-padding 15px;
    }

    &.input-active .ts-control, .ts-control{
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCAxNSAxMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5hcnJvdy1kb3duPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImZvb3Rlci9tb2JpbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yODUuMDAwMDAwLCAtMzEzLjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOTIuMjYxMTY0LCAzMTguMDAwMDAwKSBzY2FsZSgtMSwgMSkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC0yOTIuMjYxMTY0LCAtMzE4LjAwMDAwMCkgIiBwb2ludHM9IjI4OCAzMTIuNTQxMzYxIDI5My40NjE0ODMgMzE4LjAwMjg0NCAyODggMzIzLjQ1ODIwMiAyODkuNTQxMzYxIDMyNSAyOTYuNTIyMzI5IDMxOC4wMDI4NDQgMjg5LjU0MTM2MSAzMTEiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==) !important;
      background-position: right 9px center;
      background-repeat: no-repeat;
      background-size: 9px;
      background-color: unset;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .ts-dropdown{
      width: auto;
      border: $select-dropdown-border;
      border-color: $secondary-color;
      margin-top: -1px;

      .dropdown-input-wrap input{
        margin-bottom: 0;
      }
      .option{
        font-size: $input-font-size;
        white-space: nowrap;
        padding: $input-padding 15px;

        &:hover, &.active{
          color: white;
          background-color: $secondary-color;
          transition: background-color 0.3s ease 0s, color 0.3s ease 0s;

        }
      }

    }
  }
  .iti{
    width: 100%;

    .input-phone{
      margin: 0;
      border-left: none;

      &::placeholder{
        color: #aaa !important;
      }
    }
  }

}