.cookie-alert {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	line-height: 1.15;
	background-color: #f7f7f7;
	border-top: 1px solid #F2DFD6;
	padding: 20px 40px;
	z-index: 900;

	@include breakpoint(medium down) {
		padding: 20px;
	}

	.cookie-wrap {
		@include breakpoint(small only) {
			display: block;
		}
	}

	.info {
		font-size: 14px;
		margin-bottom: 0;
		padding-right: 20px;

		a {
			text-decoration: underline;
			color: #000;
		}
	}

	.button {
		min-width: 160px;
		margin-bottom: 0;

		@include breakpoint(small only) {
			margin-top: 20px;
		}
	}
}
