.mini-cart {
    .header-popup-body {
        h5{
            margin-bottom: 15px;
        }

        .product-summary{
            flex: 1;
            overflow-y: auto;
            min-height: 0;

            .card-body{
                position: relative;
                .line-item-attributes{
                    font-size: 12px;
                    .attribute{
                        align-items: baseline;
                    }
                }
            }
            .product-info{
                margin: 0;
                max-width: 220px;
            }
        }

        .callout{
            margin: 0;
        }
    }

    .mini-cart-footer {
        background-color: $white;
        // padding-top: 0;

        h5 {
            margin-bottom: 15px;
        }

        .product-tile{
            .price{
                .range.range-discount{
                    .label-range-discount{
                        font-size: 14px;
                        margin: 0 5px 0 0;
                        padding: 0;
                    }
                }
            }
        }
    }

    .header-popup-body .product-summary,
    .einstein-container .items {

        img {
            width: 90px;
        }

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background: #F7F7F7;
        }

        &::-webkit-scrollbar-thumb {
            background: black;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: black;
        }
    }

    .einstein-container .items {
        .price, .price-wrapper {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000;

            .strike-through {
                color: black;
            }
        }
    }

    .mini-cart-spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255,255,255,0.8);
        z-index: 1000;

        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: rem-calc(-25);
            margin-left: rem-calc(-25);
        }
    }

    h5 {
        margin-bottom: 30px;
    }

    .product-line-item {
        margin-bottom: 20px;
    }

    .product-info {
        display: flex;
        flex-direction: column;
    }

    .container-remove-line-item {
        position: absolute;
        top: 0;
        right: 10px;
    }

    .remove-line-item {
        margin-top: auto;

        svg {
            width: auto;
            height: auto;
            fill: gray;
        }
    }

    .estimated-total {
        margin-bottom: 10px;
        font-size: rem-calc(16);
        font-weight: 500;
        color: $black;
    }

    .line-item-pricing-info,
    .line-item-total-price-amount {
        span {
            display: none;
        }
    }

    .line-item-unit-price {
        margin-bottom: 15px;
    }

    .callout-message {
        &:first-child {
            margin-top: 0;
        }
    }

    .out-of-stock-msg {
        .callout-message {
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

/* Global */
.product-summary {
    color: $black;
    overflow-y: auto;
    overflow-x: hidden;

    .grid-x {
        &:last-child {
            .product-line-item {
                margin-bottom: 0;
            }
        }
    }

    .pricing .strike-through {
        margin-right: 10px;
    }

    .line-item-name {
        line-height: rem-calc(16);
        margin-bottom: 5px;
    }

    .line-item-availability {
        color: red;
        font-weight: 500;
        margin-top: auto;
        .out-of-stock-message{
            margin: 0;
        }
    }

    .line-item-attributes {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: auto;
        .attribute {
            display: flex;
            align-items: center;
            white-space: nowrap;
            strong {
                font-weight: 500;
                margin-left: 3px;
            }
        }

        ul {
            margin-left: 0;
        }
    }
}
