.order-track-content {
	.tracking-button {
		min-width: 175px;
	}

	.order-track-render {
		margin-top: 10px;

		@include breakpoint(medium down) {
			margin-top: 20px;
		}
	}

	.progress-bar {
		position: relative;
		width: 12px;
		height: 370px;
		border-radius: 6px;
		background-color: #f7f7f7;
		border: 1px solid #ccc;
		height: 100%;

		@include breakpoint(small only) {
			height: 200px;
		}

		&.half-progress {
			.progress {
				top: 50%;
				margin-top: -6px;
			}

			.track {
				height: calc(50% + 6px);
			}
		}

		&.full-progress {
			.progress {
				top: calc(100% - 12px);
			}

			.track {
				height: 100%;
			}
		}
	}

	.progress {
		position: absolute;
		top: -1px;
		left: -1px;
		width: 12px;
		height: 12px;
		background-color: #569017;
		border-radius: 50%;
	}

	.track {
		position: absolute;
		top: -1px;
		left: -1px;
		width: 12px;
		height: 0;
		background-color: #7ED321;
		border-radius: 6px;
	}

	.progress-date {
		font-size: 16px;
		line-height: 1.15;
		color: #000;
		padding-left: 20px;
	}

	.row-products {
		@include breakpoint(small only) {
			margin-top: 20px;
		}
	}

	.product-list {
		.card {
			&:not(:last-child) {
				margin-bottom: 20px;

				@include breakpoint(small only) {
					border-bottom: 1px solid #ccc;
					margin-bottom: 25px;
					padding-bottom: 25px;
				}
			}
		}
	}

	.product-line-item {
		.product-image {
			width: 100px;
		}

		.product-info {
			width: calc(100% - 100px);
			line-height: 1.15;
			color: #000;
			padding-left: 16px;
		}

		.price,
		.line-item-code,
		.quantity {
			margin-top: 4px;
		}

		.price {
			@include breakpoint(small only) {
				display: none;
			}
		}

		.line-item-attributes {
			margin-top: 8px;

			> .attr {
				&:not(:first-child) {
					margin-top: 4px;
				}
			}
		}

		.bottom-price {
			width: 100%;
			color: #000;
			margin-top: 20px;

			.value {
				display: inline-block;
			}
		}
	}

	.row-notes {
		background-color: #f7f7f7;
		color: #000;
		margin-top: 20px;
		padding: 20px;

		@include breakpoint(small down) {
			margin-left: -20px;
			margin-right: -20px;
		}

		.title-notes {
			font-weight: 500;
			font-size: 16px;
			line-height: 1.15;
		}

		.description {
			font-size: 16px;
			margin-top: 2px;
			margin-bottom: 0;
		}
	}

	.row-actions {
		margin-top: 20px;

		.button {
			min-width: 190px;
			margin-bottom: 0;

			@include breakpoint(small down) {
				width: 100%;
			}
		}
	}
	.margin-bottom-m{
		margin-bottom: 30px
	}
}
