.hero {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	min-height: 150px;

	@include breakpoint(medium down) {
		height: auto;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}

	&.no-overlay {
		&:before { display: none; }

		.hero-contents,
		.hero-contents .hero-body,
		.hero-contents h1 {
			color: #000;
		}
	}

	.hero-contents {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: auto;
		min-height: 150px;
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
		align-items: center;

		.category-meta {
			width: 100%;
			margin: 0 auto 20px;
			padding: 0 7%;
			position: relative;
			z-index: 4;

			@include breakpoint (medium down) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.select-categories {
			align-self: flex-end;
			position: relative;
			z-index: 2;
		}
	}

	.hero-body {
		margin: 0 auto;
		text-align: justify;
		color: #ffffff;

		h1 {
			margin-bottom: 0.8rem;
			font-size: 1.688rem;
			text-align: left;
			font-weight: bold;
			color: #ffffff;
		}

		p {
			font-size: 1rem;
			margin-bottom: 0;
		}
	}

	.hero-body-flex{
		display: flex;
	}

	.hero-category-description{
		font-size: 1rem;
		margin-bottom: 0;
	}

	.select-categories {
		background: rgba(0, 0, 0, 0.6);
		text-align: center;
		z-index: 4;
		width: 100%;
		border-top: 1px solid #ffffff;

		@include breakpoint(medium down) {
			border: 0;
		}

		select.clear {
			margin-bottom: 0;
		}

		ul.sub-categories {
			display:inline-block;
			text-align:center;
			padding: 20px 0;
			margin: 0 auto;
			max-width: 1040px;
			list-style: none;

			@include breakpoint(medium down) {
				height: 44px;
			}

			li {
				display: inline-block;
				margin: 0;

				&:before {
					content: " \00b7";
					color: #ffffff;
					display: inline-block;
					padding: 0 10px;
				}

				&:first-child {
					&:before {
						display: none;
					}
				}

				a {
					white-space: nowrap;
					color: #ffffff;
					font-weight: 500;
					font-size: 16px;
					line-height: 30px;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.nav-filters-sorting-wrapper {
	background-color: #ffffff;

	&.is-fixed {
		position: fixed;
		width: 100%;
		top: 66px;
		left: 0;
    z-index: 3;
		padding-bottom: 10px;
		border: 0;
		border-bottom: 1px solid #F2DFD6;
		animation: slideStickyNav 1s;
	}

	.nav-filters-sorting {
		display: flex;
		align-items: stretch;
		flex-grow: 1;
		flex-shrink: 0;
		justify-content: space-between;

		.filters {
			margin-top: 5px;
			margin-bottom: 0;
			display: flex;
			align-items: stretch;
			justify-content: flex-start;

			li {
				display: inline-block;
				padding: 10px 5px 0 0;
			}
		}

		.sorting {
			display: flex;
			align-items: stretch;
			justify-content: flex-end;
			margin: 10px 0 0 0;
		}

		.filter-results {
			padding: 10px 0;
			.button-counter {
				text-align: left;
				font-weight: 100;
				font-size: 12px;
				padding-top: 2px;
			}
			svg {
				margin: 0 10px;
				padding-top: 5px;
			}
		}
	}
}

@keyframes slideStickyNav {
  from {
		transform: translateY(-100px);
	}
  to {
		transform: translateY(0);
	}
}
@include breakpoint(small only) {
	.hero-launcher-push-container{
		display: none;
	}
}
body{
	#livechat-wrapper{
		z-index: 105 !important;
	}
	#oct8ne-alerts-numbers-wrapper {
		background-color:#000 !important;
		z-index: 106 !important;
	}
}

