.modal {
	&.forgot-password-modal {
		border: 1px solid #F2DFD6;
	}
}

.forgot-password-modal {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		visibility: hidden;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background-color: rgba(255, 255, 255, 0.65);
		transition: opacity 0.3s ease, visibility 0.3s ease;
	}

	&.loading {
		&::before,
		.spinner {
			visibility: visible;
			opacity: 1;
		}
	}

	.spinner {
		visibility: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0;
		transform: translate3d(-50%, -50%, 0);
	}

	.modal-content {
		padding-top: 10px;

		@include breakpoint(medium down) {
			padding-top: 40px;
		}

		@include breakpoint(medium down) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.title,
	.description {
		font-size: 16px;
	}
}

.new-password-render {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 60px;
	padding-bottom: 60px;

	@include breakpoint(small only) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	&.email-check {
		.description {
			max-width: 450px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.title {
		font-size: 40px;

		@include breakpoint(small only) {
			font-size: 34px;
		}
	}

	.description {
		font-size: 16px;
	}

	.info-description {
		font-size: 14px;
	}

	.form-group {
		max-width: 340px;
		margin-left: auto;
		margin-right: auto;

		@include breakpoint(small only) {
			max-width: none;
		}
	}

	.customer-email {
		background-color: #f7f7f7;
		border: 0;
	}
}
