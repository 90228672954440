.accordion {
	.trigger {
		display: flex;
		align-items: center;
		position: relative;
		height: 45px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1;
		color: black;
		padding: 10px;
		cursor: pointer;
		user-select: none;

		&::after {
			content: '';
			position: absolute;
			top: calc(50% - 4px);
			right: 12px;
			@include arrow(7px, 7px, black, 2px, down);
			transition: transform 0.3s ease;
		}
	}

	.expandable {
		border-top: 1px solid #d8d8d8;

		&:last-of-type {
			border-bottom: 1px solid #d8d8d8;
		}

		&.open {
			.trigger {
				&::after {
					transform: rotate(225deg);
				}
			}
		}
	}

	.content {
		display: none;
		padding: 0 10px 40px;
		line-height: 1.6em;

		a {
			color: black;
			text-decoration: underline;
		}

		ul {
			list-style-type: disc;
			margin: 0;
			padding: 0 0 0 14px;
		}
	}

	.content.description{
		a {
			text-decoration: none;
		}
	}

	&.large {
		.expandable:first-child {
			border-top: 0;
		}

		.expandable:last-of-type {
			border-bottom: 0;
		}

		.trigger {
			padding: 30px 15px;
			padding-right: 36px;
			height: auto;
			font-size: 16px;
			line-height: 1.4;

			&::after {
				top: 34px;
				right: 17px;
			}
		}

		.content {
			padding: 0 15px 40px;
			font-size: 16px;
		}
	}

	&.x-large {
		.expandable:first-child {
			border-top: 0;
		}

		.expandable:last-of-type {
			border-bottom: 0;
		}

		.trigger {
			padding: 30px 15px;
			padding-right: 36px;
			height: auto;
			font-size: 24px;
			line-height: 1.4;

			&::after {
				top: 50%;
				transform: translate(-45%, -50%) rotate(0deg);
				right: 17px;

				border-bottom: 0;
				height: 13px;
    		width: 13px;
				 border-right: 2px solid;
			}

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				transform: translate(-5%, -90%);
				right: 17px;

				border-right: 0;
				width: 13px;
				height: 13px;
				border-bottom: 2px solid;
				opacity: 1;
				transition: opacity .3s;
			}
		}

		.expandable {
			border-top: 1px solid #d8d8d8;

			&:last-of-type {
				border-bottom: 1px solid #d8d8d8;
			}

			&.open {
				.trigger {
					&::after {
						transform: translate(0, -80%) rotate(90deg);
						opacity: 0;
						transition: all .3s;
					}
				}
			}
		}

		.content {
			padding: 0 15px 40px;
			font-size: 16px;
		}
	}
}
