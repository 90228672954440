.account-profile-content {
	.info-sub-text {
		font-size: 16px;
		line-height: 1;
		margin-top: 16px;
		margin-bottom: 0;
	}

	.card-header {
		@include breakpoint(medium down) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.card-body {
		margin-top: 16px;
		max-width: 720px;

		@include breakpoint(medium down) {
			max-width: 100%;
			overflow: hidden;
		}
	}

	.main-form-group {
		@include breakpoint(medium down) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.button-save {
		width: 100%;
		max-width: 340px;

		@include breakpoint(medium down) {
			max-width: 355px;
		}
	}

	label[for="email"] {
		pointer-events: none;
	}

	input {
		&[readonly] {
			background-color: white;
			cursor: default;
			user-select: none;
		}
	}

    #state {
        display: block;
        width: 100%;
    }
}

.edit-profile-form {
	.country-name {
		.custom-select__option {
			&:disabled {
				background-color: white;
				border-width: 1px;
				text-align: left;
				color: #000;
				cursor: default;

				&:after {
					display: block;
				}
			}
		}
	}

	.title {
		font-size: 20px;
		line-height: 1;
	}

	.form-control-email {
		margin-bottom: 0;
	}

	.change-email {
		display: inline-block;
		font-size: 14px;
		font-weight: 300;
		margin-bottom: 10px;
	}

	.description {
		font-size: 16px;
		color: #555;
		margin-bottom: 0;

		&.small {
			font-size: 14px;
		}
	}

	.change-password {
		border-top: 2px solid #ccc;
		border-bottom: 2px solid #ccc;
		margin-top: 30px;
		margin-bottom: 40px;
		padding-top: 30px;
		padding-bottom: 40px;

		@include breakpoint(medium down) {
			border-top: 8px solid #f7f7f7;
			border-bottom: 8px solid #f7f7f7;
			margin-top: 20px;
			margin-bottom: 20px;
			padding-top: 20px;
			padding-bottom: 20px;
		}

		> .form-group {
			@include breakpoint(medium down) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.description {
			margin-top: 10px;
		}

		.change-password-button {
			letter-spacing: 1px;
			margin-top: 20px;
			margin-bottom: 0;

			@include breakpoint(small only) {
				display: block;
				width: 100%;
			}
 		}
	}

	.birthday {
		.description {
			max-width: 500px;
		}
	}

	.input-date,
	.input-datepicker {
		background: url('../images/cursors/arrow-down.svg') no-repeat right center;
		background-origin: content-box;
		background-size: 14px;
		max-width: 245px;

		@include breakpoint(small only) {
			display: block;
			width: 100%;
			max-width: none;
		}
	}

	.input-date-row {
		max-width: 245px;
		margin-top: 20px;

		@include breakpoint(small only) {
			display: block;
			width: 100%;
			max-width: none;
		}

		.input-date,
		.input-datepicker {
			min-height: 44px;
		}
	}

	.gender-group {
		margin-top: 40px;

		@include breakpoint(medium down) {
			margin-top: 20px;
		}
	}

	.radio-row {
		flex-wrap: wrap;
		margin-top: 20px;

		@include breakpoint(small only) {
			display: block;
		}

		.boolean-field {
			margin-bottom: 20px;
		}
	}

	.newsletter-group {
		margin-top: 20px;

		@include breakpoint(medium down) {
			margin-top: 10px;
		}

		.row-checkbox {
			margin-top: 20px;
		}

		.boolean-field {
			display: inline-flex;
			margin-bottom: 0;
			padding-right: 0;
		}

		.header-lang-newsletter {
			margin-bottom: 5px;

			@include breakpoint(small only) {
				display: block;
			}
		}

		.description {
			margin-top: 4px;
			max-width: 700px;
		}

		.custom-select__option {
			font-weight: 300;

			&--value {
				height: auto;
				line-height: 1.6;
				padding-left: 4px;
			}
		}

		.custom-select__dropdown {
			width: 100%;

			.custom-select__option {
				font-weight: 300;
				min-height: 27px;
				padding-left: 4px;
				padding-right: 4px;
			}
		}
	}

	.footer-form-group  {
		@include breakpoint(medium down) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.row-action {
		margin-top: 20px;
	}
}
