.product-promo-vertical {
  margin-bottom: 100px;
  background: linear-gradient(0deg, $primary-color calc(100% - 120px), white calc(100% - 120px));

  &.box-transparent {
    background: transparent;
  }

  @include breakpoint (small only) {
    margin-bottom: 50px;
    padding: 0 10vw;
    background: linear-gradient(0deg, $primary-color calc(100% - 60px), white calc(100% - 60px));

    &.widget .button.editorial {
      border-bottom: 2px solid #DBB19F;
  
      &::after {
        display: none;
      }
    }
  }

  .button.editorial {
    margin-top: 30px;

    @include breakpoint(small only) {
      &::before,
      &::after {
        display: none;
      }

      text-indent: 0;
      width: unset;
      height: unset;
      border-radius: 0;
      background-color: unset;
      margin-top: 15px;
    }

  }

  &__container {
    justify-content: center;

    @include breakpoint (small only) {
      &.grid-container {
        padding: 0;
      }
    }
  }

  &__editorial-box {
    width: 80%;
    margin: 0 auto;
    padding: 20px 50px 40px 50px;
    text-align: center;

    @include breakpoint (small only) {
      width: 100%;
      padding: 32px 0 30px 0;
    }

  }

  .single-image & {
    padding: 0 8%;
  }

  .double-image & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: linear-gradient(90deg, white 10%, $primary-color 10%, $primary-color 90%, white 90%);
    position: relative;

    &.box-transparent {
      background: transparent;
    }

    @include breakpoint (small only) {
      background: linear-gradient(0deg, $primary-color 80%, white 80%);
    }

    &::after {
      content: "";
      width: 100%;
      height: 20%;
      background-color: white;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;

      @include breakpoint (small only) {
        display: none;
      }
    }

    &__image {
      width: 50%;

      @include breakpoint (small only) {
        width: 100%;
      }

      &:first-of-type {
        padding-right: 20px;

        @include breakpoint (small only) {
          padding-right: 0;
        }
      }

      &:nth-of-type(2){
        padding-left: 20px;
      }
    }

    // TR-2130
    @include breakpoint (small only) {
      &:not(.image2-mobile-show) {
        > :nth-of-type(2) {
          display: none;
        }
      }
  
      &.image2-mobile-show {
        > :first-of-type {
          display: none;
        }

        // TR-2158
        .product-promo-vertical__image:nth-of-type(2) {
          padding-left: 0;
        }
      }
    }
  }

}