.sidebar-nav {
    @include breakpoint(medium down) {
        background: $whitesmoke;
        padding-bottom: 8px;
    }

    li {
        position: relative;
        background: $whitesmoke;

        @include breakpoint(medium down) {
            background: $white;
            border-bottom: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $lightgrey;

            @include breakpoint(medium down) {
                margin-bottom: 8px;
                border-bottom: 0;
            }
        }

        a {
            display: block;
            font-size: 16px;
            font-weight: 500;
            padding: 14px 20px;
            padding-bottom: 12px;
            transition: color 0.3s ease, background-color 0.3s ease;

            @include breakpoint(medium down) {
                @include flex;
                @include flex-align(justify, middle);
                color: $black;
            }

            &:hover {
                background: $primary-color;
            }

            svg {
                display: none;

                @include breakpoint(medium down) {
                    display: block;
                    width: 9px;
                    height: 14px;
                }
            }
        }


        &.is-current {
            background: $primary-color;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background: $secondary-color;
            }

            a {
                color: $black;
            }
        }
    }
}

.back-to {
    display: block;
    font-size: 16px;
    color: $black;
    text-align: center;
    padding: 18px 20px;
    position: relative;
    border-bottom: 8px solid $whitesmoke;

    @include breakpoint(medium down) {
        font-weight: 500;
    }

    svg {
        display: block;
        width: 9px;
        height: 14px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
    }
}
