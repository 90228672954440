nav {
    position: relative;

    ul {
        margin: 0;
    }

    h6 {
        margin-bottom: 0;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        line-height: rem-calc(20);

        a {
            color: $black;            
        }
    }

    .level-1 {
        @include flex;
        @include flex-align(center, middle);
        flex-wrap: wrap;

        > li {
            padding: 20px 15px 13px;

            // &:first-child {
            //     padding-left: 50px
            // }

            &:last-child {
                padding-right: 50px
            }

            > a {
                font-weight: 500;
                color: $black;
                display: block;
                position: relative;
                z-index: 2;


                &::after {
                    content: '';
                    position: absolute;
                    bottom: 4px;
                    right: 0;
                    width: 0;
                    height: 5px;
                    background: $primary-color;
                    transition: width 0.2s ease;
                    z-index: -1;
                }

                &:hover {
                    &::after {
                        left: 0;
                        right: unset;
                        width: 100%;
                    }
                }
            }

            &.featured {
                > a {
                    color: $featured;
                }
            }
        }
        .italic {
            > a {
                font-style: italic !important;
            }
        }
        .bold {
            > a {
                font-weight: bold !important;
            }
        }
    }

    .submenu {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: -1px;
        background-color: $primary-color;
        visibility: hidden;
        pointer-events: none;
        transition: all .2s ease-in;
        opacity: 0;
        z-index: 100;
    }

    .dropdown {
        &:hover {
            > .submenu {
                visibility: visible;
                pointer-events: auto;
                opacity: 1;
                transition: all .4s ease-in;
                transition-delay: .2s;
                border-bottom: 1px solid $secondary-color;
                box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.layout-1 {
    padding: 40px 60px;
    @include xy-grid;

    .col {
        @include xy-cell(1 of 5, $gutters: 40);

        &.col-2 {
            @include xy-cell(2 of 5);
        }

        &.single-text-item {
            .menuitem {
                margin-bottom: 10px;
            }
        }
    }

    .type-twocolimage {
        h6 {
            height: 38px;
            overflow: hidden;
        }
    }
}

.menuitem {

    a {
        display: block;
    }

    &.type-text {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        ul {
            margin-top: 15px;

            li {
                margin-bottom: 10px;
                line-height: rem-calc(16);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.type-twocolimage {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            margin-bottom: 20px;
            width: 100%;
            opacity: 0;
			transition: opacity 0.3s;

			&.lazyloaded {
				opacity: 1;

				~ .placeholder {
					width: 100%;
					display: none;
				}
			}

			~ .placeholder {
				width: 100%;
				display: block;
			}
        }
    }
}

@keyframes bntAnimateIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes bntAnimateOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

