.grid-footer {
	display: flex;
	align-items: center;
	margin-top: 40px;
	background: $white;
	position: relative;
	z-index: 70;

	&.loading{
		opacity: 0.2;
		pointer-events: none;
	}

	@include breakpoint(small only) {
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;
		margin-right: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.pagination-render {
		@include breakpoint(small only) {
			order: 0;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			margin-top: 20px;
			padding: 0;
		}
	}

	.show-for-large {
		@include breakpoint($breakpoint-tablet-extra down) {
			display: none;
		}
	}
	.show-for-breakpoint-tablet-extra {
		display: none;
		@include breakpoint($breakpoint-tablet-extra down) {
			display: block;
		}
	}

	.load-more-render,
	.go-to-top-render {
		@include breakpoint(small only) {
			order: -1;
			padding: 0;
		}
	}

	.load-more-render {
		@include breakpoint(small only) {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			min-height: 42px;
			margin-top: 10px;
			margin-bottom: 20px;
		}

		.counter {
			display: flex;
			justify-content: center;
			font-size: 14px;
			font-weight: bold;
		}
	}

	.go-to-top-render {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		@include breakpoint(small only) {
			position: absolute;
			top: 15px;
			right: 15px;
			order: -1;
			width: 42px;
			height: 42px;
		}
	}
}

.show-more-previous{
	margin: 40px 0;

	@include breakpoint(medium only) {
		margin: 15px 0;
	}
}

.show-more, .show-more-previous{
	position: relative;
	font-size: 16px;

	.button-load-more {
		box-shadow: 0 2px 0 0 $secondary-color;
		padding-bottom: 5px;

		&:active {
			color: #000;
		}
	}
}

.button{
	&-load-more {
		text-transform: uppercase;
	}

	&-goto-top {
		position: relative;
		background-color: $primary-color;
		width: 42px;
		height: 42px;

		&::after {
			content: '';
			position: absolute;
			top: 41%;
			left: 38%;
			@include arrow(10px, 10px, black, 2px, up);

			@include breakpoint(small only) {
				top: 45%;
				width: 8px;
				height: 8px;
			}
		}

		span {
			display: none;
		}
	}
}

.plp-pagination-border{
	@include breakpoint(small only) {
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}
}

.plp-pagination {
	display: table;

	@include breakpoint(small only) {
		width: 100%;
		text-align: center;
		order: 0;
		margin-top: 20px;
		padding: 0;
	}

	.items-pagination {
		display: inline-block;
	}

	.pagination {
		margin-left: 0;
		margin: rem-calc(10 0 10 0);
		list-style-position: outside;
		line-height: rem-calc(18);
		list-style-type: disc;

		@include breakpoint(small only) {
			position: relative;
		}

		li {
			margin-right: 0.0625rem;
			display: inline-block;
			text-align: center;
			font-size: rem-calc(14);
			font-weight: 500;

			@include breakpoint(small only) {
				margin-right: 0;
			}

			&.pagination-ctrl {
				@include breakpoint(small only) {
					display: inline-flex;
					vertical-align: middle;
					max-width: 50px;
					min-height: 18px;
					margin-top: -9px;
				}
			}

			button {
				display: block;
				padding: rem-calc(2 6);
				color: $black;
				box-shadow: 0 2px 0 0 transparent;
				transition: box-shadow 0.3s ease;

				&:hover {
					box-shadow: 0 2px 0 0 $secondary-color;
				}
			}
		}

		.current button {
			padding: rem-calc(2 6);
			box-shadow: 0 2px 0 0 $secondary-color;
			cursor: default;
		}

		.disabled {
			color: $light-gray;
			cursor: default;
		}
	}

	.pagination-ctrl {
		position: relative;
		width: 14px;
		height: 15px;

		@include breakpoint(small only) {
			width: auto;
			height: auto;
		}

		.pagination-label {
			position: absolute;
			top: 0;
			width: 14px;
			height: 19px;
			color: $black;
			cursor: pointer;

			@include breakpoint(small only) {
				position: static;
				width: auto;
				height: auto;
				font-weight: 300;
				padding: 0;
			}
		}

		.disabled {
			cursor: default;
		}
	}

	.pagination-prev,
	.pagination-next {
		z-index: 1;

		@include breakpoint(small only) {
			position: absolute;
			top: 50%;
		}

		&::after,
		&::before {
			content: '';
			position: absolute;
			top: calc(50% - 1px);
			z-index: -1;

			@include breakpoint(small only) {
				position: relative;
				top: 4px;
			}
		}
	}

	.pagination-prev {
		@include breakpoint(small only) {
			left: 0;
		}

		a {
			left: 0;
		}

		&::before {
			@include arrow(7px, 7px, black, 2px, left);

			@include breakpoint(small only) {
				width: 10px;
				height: 10px;
				margin-right: 10px;
			}
		}
	}

	.pagination-next {
		margin-left: 5px;

		@include breakpoint(small only) {
			right: 0;
			margin-left: 0;
		}

		a {
			right: 0;
		}

		&::after {
			@include arrow(7px, 7px, black, 2px, right);

			@include breakpoint(small only) {
				width: 10px;
				height: 10px;
				margin-left: 10px;
			}
		}
	}

	.ellipsis::after {
		padding: 0.1875rem 0.625rem;
		content: '\2026';
	}
}

@include breakpoint(medium down) {
   .footer-wrapper {
     margin-bottom: 30px; // TR-993
   }
}